import Vue from 'vue'
import firebase from 'firebase/app'
import moment from 'moment'
import router from '../../router/router'
import { ERROR, PROFILE_IMAGE_ERROR, GET_USER_PROFILE, GET_USER_PROFILE_PENDING, GET_USER_NAME, GET_USER_EMAIL, GET_PROFILE_STATUS, GET_USER_DATA_PENDING, GET_PROFILE_IMAGE_URL, GET_PROFILE_IMAGE, GET_PROFILE_IMAGE_PENDING, GET_DISABLED, UPDATE_USER_SUBSCRIPTION_STATUS, GET_BACKDROP_LOADER, GET_DISABLED_BUTTON, UPDATE_USER_SUBSCRIPTION_TYPE, UPDATE_USER_PROFILE_IMAGE } from '../mutation_types'

const FIREBASE_FUNCTION_CANCEL = process.env.VUE_APP_FIREBASE_FUNCTION_CANCEL

const FIREBASE_STANDARD_SUBSCRIBE = process.env.VUE_APP_FIREBASE_STANDARD_SUBSCRIBE

const FIREBASE_PREMIUM_SUBSCRIBE = process.env.VUE_APP_FIREBASE_PREMIUM_SUBSCRIBE

const FIREBASE_ULTIMATE_SUBSCRIBE = process.env.VUE_APP_FIREBASE_ULTIMATE_SUBSCRIBE

const PUBLISHABLE_KEY = process.env.VUE_APP_PUBLISHABLE_KEY

var userProfileRef = firebase.database().ref('users')
var storageRef = firebase.storage()

const user = {
  state: {
    error: null,
    profileImageError: null,
    userProfile: null,
    userProfilePending: false,
    userDataPending: false,
    userName: null,
    userEmail: null,
    profileStatus: null,
    profileImage: false,
    profileImageUrl: null,
    profileImagePending: false,
    disabled: false,
    backdropLoader: false,
    disabledButton: false
  },
  getters: {
    getError (state) {
      return state.error
    },
    getProfileImageError (state) {
      return state.profileImageError
    },
    getUserName (state) {
      return state.userName
    },
    getUserEmail (state) {
      return state.userEmail
    },
    getProfileStatus (state) {
      return state.profileStatus
    },
    getUserProfile (state) {
      return state.userProfile
    },
    getUserProfilePending (state) {
      return state.userProfilePending
    },
    getUserDataPending (state) {
      return state.userDataPending
    },
    getProfileImageUrl (state) {
      return state.profileImageUrl
    },
    getProfileImagePending (state) {
      return state.profileImagePending
    },
    getDisabled (state) {
      return state.disabled
    },
    getBackdropLoader (state) {
      return state.backdropLoader
    },
    getDisabledLoader (state) {
      return state.disabledButton
    }
  },
  mutations: {
    [GET_USER_NAME] (state, name) {
      state.userName = name
    },
    [GET_USER_EMAIL] (state, email) {
      state.userEmail = email
    },
    [GET_PROFILE_STATUS] (state, status) {
      state.profileStatus = status
    },
    [ERROR] (state, error) {
      state.error = error
    },
    [PROFILE_IMAGE_ERROR] (state, error) {
      state.profileImageError = error
    },
    [GET_USER_PROFILE] (state, value) {
      state.userProfile = value
    },
    [GET_USER_PROFILE_PENDING] (state, value) {
      state.userProfilePending = value
    },
    [GET_USER_DATA_PENDING] (state, value) {
      state.userDataPending = value
    },
    [GET_PROFILE_IMAGE] (state, value) {
      state.profileImage = value
    },
    [GET_PROFILE_IMAGE_URL] (state, value) {
      state.profileImageUrl = value
    },
    [GET_PROFILE_IMAGE_PENDING] (state, value) {
      state.profileImagePending = value
    },
    [GET_DISABLED] (state, value) {
      state.disabled = value
    },
    [UPDATE_USER_SUBSCRIPTION_STATUS] (state, value) {
      state.userProfile[0].subscription = value
    },
    [UPDATE_USER_SUBSCRIPTION_TYPE] (state, value) {
      state.userProfile[0].type = value
    },
    [UPDATE_USER_PROFILE_IMAGE] (state, value) {
      state.userProfile[0].profileImage = value
    },
    [GET_BACKDROP_LOADER] (state, value) {
      state.backdropLoader = value
    },
    [GET_DISABLED_BUTTON] (state, value) {
      state.disabledButton = value
    }
  },
  actions: {
    getUserData (context) {
      if (!context.state.userName) {
        firebase.auth().onAuthStateChanged(user => {
          if (user) {
            context.commit(GET_USER_DATA_PENDING, false)
            context.commit(ERROR, null)
            context.commit(GET_USER_NAME, user.displayName)
            context.commit(GET_USER_EMAIL, user.email)
            context.commit(GET_PROFILE_STATUS, user.emailVerified)
            context.commit(GET_USER_DATA_PENDING, true)
          } else {
            context.commit(ERROR, null)
            context.commit(GET_USER_DATA_PENDING, true)
          }
        })
      }
    },
    signIn (context, payload) {
      firebase.auth().signInWithEmailAndPassword(payload.email, payload.password)
        .then(() => {
          router.push('/profile')
        })
        .catch(error => {
          context.commit(ERROR, error.message)
          context.commit(GET_DISABLED, false)
        })
    },
    signUp (context, payload) {
      firebase.auth().createUserWithEmailAndPassword(payload.email, payload.password)
        .then(() => {
          var user = firebase.auth().currentUser
          user.sendEmailVerification()
          user.updateProfile({
            displayName: payload.name
          }).then(function () {
            context.commit(GET_USER_NAME, payload.name)
            userProfileRef.child(user.uid).set({ type: 1, profileImage: false })
              .then(function () {
                router.push('/profile')
              })
              .catch(function (error) {
                context.commit(ERROR, error.message)
                context.commit(GET_DISABLED, false)
              })
          })
            .catch(function (error) {
              context.commit(ERROR, error.message)
              context.commit(GET_DISABLED, false)
            })
        })
        .catch(error => {
          context.commit(ERROR, error.message)
          context.commit(GET_DISABLED, false)
        })
    },
    clearError (context) {
      context.commit(ERROR, null)
    },
    clearImageError (context) {
      context.commit(PROFILE_IMAGE_ERROR, null)
    },
    getUserProfile (context) {
      if (!context.state.userProfile) {
        context.commit(GET_USER_PROFILE_PENDING, false)
        var userData = []

        var user = firebase.auth().currentUser

        if (user) {
          userProfileRef.child(user.uid).once('value', snapshot => {
            userData.push({ ...snapshot.val() }, context.commit(GET_USER_PROFILE_PENDING, true))
          })

          context.commit(GET_USER_PROFILE, userData)
        } else {
          context.commit(GET_USER_PROFILE_PENDING, true)
        }
      } else {
        context.commit(GET_USER_PROFILE_PENDING, true)
      }
    },
    updateName (context, payload) {
      var user = firebase.auth().currentUser
      user.updateProfile({
        displayName: payload.name
      }).then(() => {
        context.commit(GET_USER_NAME, payload.name)
        Vue.swal({
          type: 'success',
          title: 'Name successfully updated',
          confirmButtonText: 'OK'
        })
      })
        .catch(error => {
          context.commit(ERROR, error.message)
          Vue.swal({
            type: 'error',
            title: 'Error occurred',
            text: 'Please try again later',
            confirmButtonText: 'OK'
          })
        })
    },
    updateEmail (context, payload) {
      var user = firebase.auth().currentUser

      user.updateEmail(payload.email).then(function () {
        context.commit(GET_USER_EMAIL, payload.email)
        context.commit(GET_PROFILE_STATUS, false)
        user.sendEmailVerification()
        Vue.swal({
          type: 'success',
          title: 'E-mail address successfully updated',
          confirmButtonText: 'OK'
        })
      }).catch(function (error) {
        context.commit(ERROR, error.message)
      })
    },
    updatePassword (context, payload) {
      var user = firebase.auth().currentUser

      user.updatePassword(payload.password).then(function () {
        Vue.swal({
          type: 'success',
          title: 'Password successfully updated',
          confirmButtonText: 'OK'
        })
      }).catch(function (error) {
        context.commit(ERROR, error.message)
      })
    },
    resetPassword (context, payload) {
      var auth = firebase.auth()

      auth.sendPasswordResetEmail(payload.email).then(function () {
        router.push('/reset-password-feedback')
      }).catch(function (error) {
        context.commit(ERROR, error.message)
        context.commit(GET_DISABLED, false)
      })
    },
    deleteAccount (context, payload) {
      var user = firebase.auth().currentUser
      var uid = firebase.auth().currentUser.uid

      var jobsRef = firebase.database().ref('jobs')
      var userJobsRef = firebase.database().ref('jobs').orderByChild('user').equalTo(uid)

      var agenciesRef = firebase.database().ref('agencies')
      var userAgenciesRef = firebase.database().ref('agencies').orderByChild('user').equalTo(uid)

      var eventsRef = firebase.database().ref('events')
      var userEventsRef = firebase.database().ref('events').orderByChild('user').equalTo(uid)

      var logsRef = firebase.database().ref('logs')

      // delete jobs
      userJobsRef.once('value', snapshot => {
        if (snapshot.val() != null) {
          var userJobs = Object.keys(snapshot.val())

          userJobs.forEach(function (value) {
            jobsRef.child(value).remove()
          })
        }
      })

      // delete agencies
      userAgenciesRef.once('value', snapshot => {
        if (snapshot.val() != null) {
          var userAgencies = []
          userAgencies.push({ ...snapshot.val() })

          Object.values(userAgencies).forEach(function (value) {
            Object.values(value).forEach(function (element) {
              storageRef.ref('agencies/' + user.uid + '/' + element.image).delete()
            })
            Object.keys(value).forEach(function (item) {
              agenciesRef.child(item).remove()
            })
          })
        }
      })

      // delete events
      userEventsRef.once('value', snapshot => {
        if (snapshot.val() != null) {
          var userEvents = []
          userEvents.push({ ...snapshot.val() })

          Object.values(userEvents).forEach(function (value) {
            Object.values(value).forEach(function (element) {
              storageRef.ref('events/' + user.uid + '/' + element.image).delete()
            })
            Object.keys(value).forEach(function (item) {
              eventsRef.child(item).remove()
            })
          })
        }
      })

      setTimeout(
        function () {
          user.delete().then(function () {
            userProfileRef.child(uid).remove()
            // log
            var currentDate = moment(new Date()).format('DD-MM-YYYY HH:mm')
            logsRef.push().set({ user: uid, type: 1, date: currentDate, recent: true })
              .then(function () {
                if (payload.profileImage == true) {
                  storageRef.ref('users/' + uid + '/' + 'profile').delete()
                    .then(() => {
                      context.commit(GET_USER_NAME, null)
                      context.commit(GET_USER_EMAIL, null)
                      context.commit(GET_PROFILE_STATUS, null)
                      context.commit(GET_USER_PROFILE, null)
                      router.push('/')
                      Vue.swal({
                        type: 'info',
                        title: 'Your account has been successfully deleted',
                        confirmButtonText: 'OK'
                      })
                    })
                    .catch(function (error) {
                      context.commit(ERROR, error.message)
                    })
                } else {
                  context.commit(GET_USER_NAME, null)
                  context.commit(GET_USER_EMAIL, null)
                  context.commit(GET_PROFILE_STATUS, null)
                  context.commit(GET_USER_PROFILE, null)
                  router.push('/')
                  Vue.swal({
                    type: 'info',
                    title: 'Your account has been successfully deleted',
                    confirmButtonText: 'OK'
                  })
                }
              })
              .catch(function (error) {
                context.commit(ERROR, error.message)
              })
          }).catch(function (error) {
            context.commit(ERROR, error.message)
          })
        }, 2000
      )
    },
    getProfileImage (context) {
      if (!context.state.profileImagePending) {
        context.commit(GET_PROFILE_IMAGE_PENDING, false)
        context.commit(GET_PROFILE_IMAGE, false)
        context.commit(PROFILE_IMAGE_ERROR, null)

        var user = firebase.auth().currentUser

        userProfileRef.child(user.uid).once('value', snapshot => {
          if (snapshot.val()) {
            context.commit(GET_PROFILE_IMAGE, snapshot.val().profileImage)
            if (context.state.profileImage == true) {
              // get photo
              var profileImage = storageRef.ref('users/' + user.uid + '/' + 'profile')
              profileImage.getDownloadURL().then(function (url) {
                context.commit(GET_PROFILE_IMAGE_URL, url)
                context.commit(GET_PROFILE_IMAGE_PENDING, true)
              }).catch(function (error) {
                context.commit(PROFILE_IMAGE_ERROR, error.message)
              })
            } else {
              context.commit(GET_PROFILE_IMAGE_PENDING, true)
            }
          } else {
            context.commit(GET_PROFILE_IMAGE_PENDING, true)
          }
        })
      }
    },
    updateProfileImage (context, payload) {
      var user = firebase.auth().currentUser

      storageRef.ref('users/' + user.uid + '/' + 'profile').put(payload.file)
        .then(() => {
          // get new photo
          var profileImage = storageRef.ref('users/' + user.uid + '/' + 'profile')
          profileImage.getDownloadURL().then((url) => {
            context.commit(UPDATE_USER_PROFILE_IMAGE, true)
            context.commit(GET_PROFILE_IMAGE_URL, url)
            userProfileRef.child(user.uid).update({ profileImage: true }).then(() => {
              context.commit(GET_PROFILE_IMAGE, true)
            })
          })
        })
        .catch(error => {
          context.commit(PROFILE_IMAGE_ERROR, error.message)
        })
    },
    deleteProfileImage (context) {
      var user = firebase.auth().currentUser

      storageRef.ref('users/' + user.uid + '/' + 'profile').delete()
        .then(() => {
          userProfileRef.child(user.uid).update({ profileImage: false }).then(() => {
            context.commit(UPDATE_USER_PROFILE_IMAGE, false)
            context.commit(GET_PROFILE_IMAGE, false)
            context.commit(GET_PROFILE_IMAGE_URL, null)
          })
        })
        .catch(function (error) {
          context.commit(PROFILE_IMAGE_ERROR, error.message)
        })
    },
    setDisabled (context) {
      context.commit(GET_DISABLED, true)
    },
    clearDisabled (context) {
      context.commit(GET_DISABLED, false)
    },
    sendEmailVerification () {
      var user = firebase.auth().currentUser
      user.sendEmailVerification().then(function () {
        Vue.swal({
          type: 'success',
          title: 'Email verification has been sent to your e-mail address',
          text: 'Please check your mailbox',
          confirmButtonText: 'OK'
        })
      }).catch(function (error) {
        Vue.swal({
          type: 'error',
          title: 'Error occurred',
          text: 'Please try again later',
          confirmButtonText: 'OK'
        })
      })
    },
    async cancelSubscription (context, payload) {
      var cancel = payload.cancel
      const res = await fetch(FIREBASE_FUNCTION_CANCEL, {
        mode: 'cors',
        method: 'POST',
        body: JSON.stringify({
          cancel
        })
      })
      const data = await res.json()
      if (data.statusCode === 200) {
        var user = firebase.auth().currentUser

        var usersRef = firebase.database().ref('users')
        var logsRef = firebase.database().ref('logs')

        usersRef.child(user.uid).update({ subscription: 4, next: null, cancel: null })
          .then(function () {
            // log
            var currentDate = moment(new Date()).format('DD-MM-YYYY HH:mm')
            logsRef.push().set({ user: user.uid, type: 4, date: currentDate, recent: true })

            if (context.state.userProfile) {
              context.commit(UPDATE_USER_SUBSCRIPTION_STATUS, 4)
              Vue.swal({
                type: 'success',
                title: 'Subscription canceled',
                text: "Your subscription has been canceled. For more details visit 'Subscription' panel on your profile page.",
                confirmButtonText: 'OK'
              })
            } else {
              Vue.swal({
                type: 'success',
                title: 'Subscription canceled',
                text: "Your subscription has been canceled. For more details visit 'Subscription' panel on your profile page.",
                confirmButtonText: 'OK'
              })
            }
          })
          .catch(function (error) {
            Vue.swal({
              type: 'error',
              title: 'Error occurred',
              text: 'Please try again later',
              confirmButtonText: 'OK'
            })
          })
      } else {
        Vue.swal({
          type: 'error',
          title: 'Error occurred',
          text: 'Please try again later',
          confirmButtonText: 'OK'
        })
      }
    },
    subscribeToStandard (context) {
      Stripe.setPublishableKey(PUBLISHABLE_KEY)
      context.commit(GET_DISABLED_BUTTON, true)
      var handler = StripeCheckout.configure({
        key: PUBLISHABLE_KEY,
        image: require('@/assets/images/shared/icon-1.png'),
        locale: 'auto',
        closed: function () {
          context.commit(GET_DISABLED_BUTTON, false)
        },
        token: function (token) {
          doPayment(token).then((result) => {
            if (document.getElementById('gatewayIframe') !== null) {
              document.getElementById('gatewayIframe').style.display = 'none'
            }
            (async function () {
              context.commit(GET_BACKDROP_LOADER, true)
              var user = firebase.auth().currentUser
              result.uid = user.uid
              const res = await fetch(FIREBASE_STANDARD_SUBSCRIBE, {
                mode: 'cors',
                method: 'POST',
                body: JSON.stringify({
                  result
                })
              })
              const data = await res.json()
              if (data.statusCode === 200) {
                context.commit(GET_BACKDROP_LOADER, false)
                context.commit(GET_DISABLED_BUTTON, false)
                if (context.state.userProfile) {
                  context.commit(UPDATE_USER_SUBSCRIPTION_STATUS, 2)
                  context.commit(UPDATE_USER_SUBSCRIPTION_TYPE, 2)
                  Vue.swal({
                    type: 'success',
                    title: 'Subscription successfully completed!',
                    text: "You have successfully subscribed to the STANDARD plan. We will verify your transaction and upgrade your profile as soon as possible. Notice that this process can take up to 48 hours. For more details visit 'Subscription' panel on your profile page.",
                    confirmButtonText: 'OK'
                  })
                } else {
                  Vue.swal({
                    type: 'success',
                    title: 'Subscription successfully completed!',
                    text: "You have successfully subscribed to the STANDARD plan. We will verify your transaction and upgrade your profile as soon as possible. Notice that this process can take up to 48 hours. For more details visit 'Subscription' panel on your profile page.",
                    confirmButtonText: 'OK'
                  })
                }
              } else {
                context.commit(GET_BACKDROP_LOADER, false)
                context.commit(GET_DISABLED_BUTTON, false)
                Vue.swal({
                  type: 'error',
                  title: 'Error occurred',
                  text: 'Please try again later',
                  confirmButtonText: 'OK'
                })
              }
            }())
          }).catch((error) => {
            console.error(error)
            if (document.getElementById('gatewayIframe') !== null) {
              document.getElementById('gatewayIframe').style.display = 'none'
            }
            Vue.swal({
              type: 'error',
              title: 'Error occurred',
              text: '3D Secure authentication failed',
              confirmButtonText: 'OK'
            })
          })

          function doPayment (token) {
            return new Promise((resolve, reject) => {
              const onCreateCardCallback = create3DSecure(token, resolve, reject)
              return Stripe.source.create({
                type: 'card',
                token: token.id
              }, onCreateCardCallback)
            })
          }

          function create3DSecure (token, resolve, reject) {
            return (status, cardResponse) => {
              if (status !== 200 || cardResponse.error) {
                reject(cardResponse.error)
              } else if (cardResponse.card.three_d_secure === 'not_supported' && cardResponse.status === 'chargeable') {
                resolve(cardResponse)
              } else if (cardResponse.card.three_d_secure === 'optional' || cardResponse.card.three_d_secure === 'required') {
                const onCreate3DSecureCallback = createIframe(token, resolve, reject)

                Stripe.source.create({
                  type: 'three_d_secure',
                  amount: 20000,
                  currency: 'usd',
                  three_d_secure: {
                    card: cardResponse.id
                  },
                  redirect: {
                    return_url: window.location.href
                  }
                }, onCreate3DSecureCallback)
              } else {
                reject(cardResponse)
              }
            }
          }

          function createIframe (token, resolve, reject) {
            return (status, stripe3dsResponse) => {
              if (status !== 200 || stripe3dsResponse.error) {
                reject(stripe3dsResponse.error)
              } else {
                document.getElementById('verificationGateway').innerHTML = '<iframe id="gatewayIframe" frameborder="0" src="' + stripe3dsResponse.redirect.url + '"></iframe>'

                const onPollCallbackReal = onPollCallback(token, resolve, reject)
                Stripe.source.poll(stripe3dsResponse.id, stripe3dsResponse.client_secret, onPollCallbackReal)
              }
            }
          }

          function onPollCallback (token, resolve, reject) {
            return (status, source) => {
              if (status !== 200 || source.error) {
                reject(source.error)
              } else if (source.status === 'canceled' || source.status === 'consumed' || source.status === 'failed') {
                reject(source.status)
              } else if (source.status === 'chargeable') {
                resolve(source)
              }
            }
          }
        }
      })

      handler.open({
        name: 'Branding Pavilion',
        description: 'Standard subscription for 1 year',
        amount: 20000,
        currency: 'usd'
      })

      // Close Checkout on page navigation:
      window.addEventListener('popstate', function () {
        handler.close()
      })
    },
    subscribeToPremium (context) {
      Stripe.setPublishableKey(PUBLISHABLE_KEY)
      context.commit(GET_DISABLED_BUTTON, true)
      var handler = StripeCheckout.configure({
        key: PUBLISHABLE_KEY,
        image: require('@/assets/images/shared/icon-1.png'),
        locale: 'auto',
        closed: function () {
          context.commit(GET_DISABLED_BUTTON, false)
        },
        token: function (token) {
          doPayment(token).then((result) => {
            if (document.getElementById('gatewayIframe') !== null) {
              document.getElementById('gatewayIframe').style.display = 'none'
            }
            (async function () {
              context.commit(GET_BACKDROP_LOADER, true)
              var user = firebase.auth().currentUser
              result.uid = user.uid
              const res = await fetch(FIREBASE_PREMIUM_SUBSCRIBE, {
                mode: 'cors',
                method: 'POST',
                body: JSON.stringify({
                  result
                })
              })
              const data = await res.json()
              if (data.statusCode === 200) {
                context.commit(GET_BACKDROP_LOADER, false)
                context.commit(GET_DISABLED_BUTTON, false)
                if (context.state.userProfile) {
                  context.commit(UPDATE_USER_SUBSCRIPTION_STATUS, 2)
                  context.commit(UPDATE_USER_SUBSCRIPTION_TYPE, 3)
                  Vue.swal({
                    type: 'success',
                    title: 'Subscription successfully completed!',
                    text: "You have successfully subscribed to the PREMIUM plan. We will verify your transaction and upgrade your profile as soon as possible. Notice that this process can take up to 48 hours. For more details visit 'Subscription' panel on your profile page.",
                    confirmButtonText: 'OK'
                  })
                } else {
                  Vue.swal({
                    type: 'success',
                    title: 'Subscription successfully completed!',
                    text: "You have successfully subscribed to the PREMIUM plan. We will verify your transaction and upgrade your profile as soon as possible. Notice that this process can take up to 48 hours. For more details visit 'Subscription' panel on your profile page.",
                    confirmButtonText: 'OK'
                  })
                }
              } else {
                context.commit(GET_BACKDROP_LOADER, false)
                context.commit(GET_DISABLED_BUTTON, false)
                Vue.swal({
                  type: 'error',
                  title: 'Error occurred',
                  text: 'Please try again later',
                  confirmButtonText: 'OK'
                })
              }
            }())
          }).catch((error) => {
            console.error(error)
            if (document.getElementById('gatewayIframe') !== null) {
              document.getElementById('gatewayIframe').style.display = 'none'
            }
            Vue.swal({
              type: 'error',
              title: 'Error occurred',
              text: '3D Secure authentication failed',
              confirmButtonText: 'OK'
            })
          })

          function doPayment (token) {
            return new Promise((resolve, reject) => {
              const onCreateCardCallback = create3DSecure(token, resolve, reject)
              return Stripe.source.create({
                type: 'card',
                token: token.id
              }, onCreateCardCallback)
            })
          }

          function create3DSecure (token, resolve, reject) {
            return (status, cardResponse) => {
              if (status !== 200 || cardResponse.error) {
                reject(cardResponse.error)
              } else if (cardResponse.card.three_d_secure === 'not_supported' && cardResponse.status === 'chargeable') {
                resolve(cardResponse)
              } else if (cardResponse.card.three_d_secure === 'optional' || cardResponse.card.three_d_secure === 'required') {
                const onCreate3DSecureCallback = createIframe(token, resolve, reject)

                Stripe.source.create({
                  type: 'three_d_secure',
                  amount: 50000,
                  currency: 'usd',
                  three_d_secure: {
                    card: cardResponse.id
                  },
                  redirect: {
                    return_url: window.location.href
                  }
                }, onCreate3DSecureCallback)
              } else {
                reject(cardResponse)
              }
            }
          }

          function createIframe (token, resolve, reject) {
            return (status, stripe3dsResponse) => {
              if (status !== 200 || stripe3dsResponse.error) {
                reject(stripe3dsResponse.error)
              } else {
                document.getElementById('verificationGateway').innerHTML = '<iframe id="gatewayIframe" frameborder="0" src="' + stripe3dsResponse.redirect.url + '"></iframe>'

                const onPollCallbackReal = onPollCallback(token, resolve, reject)
                Stripe.source.poll(stripe3dsResponse.id, stripe3dsResponse.client_secret, onPollCallbackReal)
              }
            }
          }

          function onPollCallback (token, resolve, reject) {
            return (status, source) => {
              if (status !== 200 || source.error) {
                reject(source.error)
              } else if (source.status === 'canceled' || source.status === 'consumed' || source.status === 'failed') {
                reject(source.status)
              } else if (source.status === 'chargeable') {
                resolve(source)
              }
            }
          }
        }
      })

      handler.open({
        name: 'Branding Pavilion',
        description: 'Premium subscription for 1 year',
        amount: 50000,
        currency: 'usd'
      })

      // Close Checkout on page navigation:
      window.addEventListener('popstate', function () {
        handler.close()
      })
    },
    subscribeToUltimate (context) {
      Stripe.setPublishableKey(PUBLISHABLE_KEY)
      context.commit(GET_DISABLED_BUTTON, true)
      var handler = StripeCheckout.configure({
        key: PUBLISHABLE_KEY,
        image: require('@/assets/images/shared/icon-1.png'),
        locale: 'auto',
        closed: function () {
          context.commit(GET_DISABLED_BUTTON, false)
        },
        token: function (token) {
          doPayment(token).then((result) => {
            if (document.getElementById('gatewayIframe') !== null) {
              document.getElementById('gatewayIframe').style.display = 'none'
            }
            (async function () {
              context.commit(GET_BACKDROP_LOADER, true)
              var user = firebase.auth().currentUser
              result.uid = user.uid
              const res = await fetch(FIREBASE_ULTIMATE_SUBSCRIBE, {
                mode: 'cors',
                method: 'POST',
                body: JSON.stringify({
                  result
                })
              })
              const data = await res.json()
              if (data.statusCode === 200) {
                context.commit(GET_BACKDROP_LOADER, false)
                context.commit(GET_DISABLED_BUTTON, false)
                if (context.state.userProfile) {
                  context.commit(UPDATE_USER_SUBSCRIPTION_STATUS, 2)
                  context.commit(UPDATE_USER_SUBSCRIPTION_TYPE, 4)
                  Vue.swal({
                    type: 'success',
                    title: 'Subscription successfully completed!',
                    text: "You have successfully subscribed to the ULTIMATE plan. We will verify your transaction and upgrade your profile as soon as possible. Notice that this process can take up to 48 hours. For more details visit 'Subscription' panel on your profile page.",
                    confirmButtonText: 'OK'
                  })
                } else {
                  Vue.swal({
                    type: 'success',
                    title: 'Subscription successfully completed!',
                    text: "You have successfully subscribed to the ULTIMATE plan. We will verify your transaction and upgrade your profile as soon as possible. Notice that this process can take up to 48 hours. For more details visit 'Subscription' panel on your profile page.",
                    confirmButtonText: 'OK'
                  })
                }
              } else {
                context.commit(GET_BACKDROP_LOADER, false)
                context.commit(GET_DISABLED_BUTTON, false)
                Vue.swal({
                  type: 'error',
                  title: 'Error occurred',
                  text: 'Please try again later',
                  confirmButtonText: 'OK'
                })
              }
            }())
          }).catch((error) => {
            console.error(error)
            if (document.getElementById('gatewayIframe') !== null) {
              document.getElementById('gatewayIframe').style.display = 'none'
            }
            Vue.swal({
              type: 'error',
              title: 'Error occurred',
              text: '3D Secure authentication failed',
              confirmButtonText: 'OK'
            })
          })

          function doPayment (token) {
            return new Promise((resolve, reject) => {
              const onCreateCardCallback = create3DSecure(token, resolve, reject)
              return Stripe.source.create({
                type: 'card',
                token: token.id
              }, onCreateCardCallback)
            })
          }

          function create3DSecure (token, resolve, reject) {
            return (status, cardResponse) => {
              if (status !== 200 || cardResponse.error) {
                reject(cardResponse.error)
              } else if (cardResponse.card.three_d_secure === 'not_supported' && cardResponse.status === 'chargeable') {
                resolve(cardResponse)
              } else if (cardResponse.card.three_d_secure === 'optional' || cardResponse.card.three_d_secure === 'required') {
                const onCreate3DSecureCallback = createIframe(token, resolve, reject)

                Stripe.source.create({
                  type: 'three_d_secure',
                  amount: 100000,
                  currency: 'usd',
                  three_d_secure: {
                    card: cardResponse.id
                  },
                  redirect: {
                    return_url: window.location.href
                  }
                }, onCreate3DSecureCallback)
              } else {
                reject(cardResponse)
              }
            }
          }

          function createIframe (token, resolve, reject) {
            return (status, stripe3dsResponse) => {
              if (status !== 200 || stripe3dsResponse.error) {
                reject(stripe3dsResponse.error)
              } else {
                document.getElementById('verificationGateway').innerHTML = '<iframe id="gatewayIframe" frameborder="0" src="' + stripe3dsResponse.redirect.url + '"></iframe>'

                const onPollCallbackReal = onPollCallback(token, resolve, reject)
                Stripe.source.poll(stripe3dsResponse.id, stripe3dsResponse.client_secret, onPollCallbackReal)
              }
            }
          }

          function onPollCallback (token, resolve, reject) {
            return (status, source) => {
              if (status !== 200 || source.error) {
                reject(source.error)
              } else if (source.status === 'canceled' || source.status === 'consumed' || source.status === 'failed') {
                reject(source.status)
              } else if (source.status === 'chargeable') {
                resolve(source)
              }
            }
          }
        }
      })

      handler.open({
        name: 'Branding Pavilion',
        description: 'Ultimate subscription for 1 year',
        amount: 100000,
        currency: 'usd'
      })

      // Close Checkout on page navigation:
      window.addEventListener('popstate', function () {
        handler.close()
      })
    }
  }
}

export default user
