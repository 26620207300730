// Global
export const ERROR = 'ERROR'
export const GET_DISABLED = 'GET_DISABLED'
export const RESET = 'reset'

// User
export const GET_USER_PROFILE = 'GET_USER_PROFILE'
export const GET_USER_PROFILE_PENDING = 'GET_USER_PROFILE_PENDING'
export const GET_USER_NAME = 'GET_USER_NAME'
export const GET_USER_EMAIL = 'GET_USER_EMAIL'
export const GET_PROFILE_STATUS = 'GET_PROFILE_STATUS'
export const GET_USER_DATA_PENDING = 'GET_USER_DATA_PENDING'
export const GET_PROFILE_IMAGE_URL = 'GET_PROFILE_IMAGE_URL'
export const PROFILE_IMAGE_ERROR = 'PROFILE_IMAGE_ERROR'
export const GET_PROFILE_IMAGE = 'GET_PROFILE_IMAGE'
export const GET_PROFILE_IMAGE_PENDING = 'GET_PROFILE_IMAGE_PENDING'
export const UPDATE_USER_SUBSCRIPTION_STATUS = 'UPDATE_USER_SUBSCRIPTION_STATUS'
export const UPDATE_USER_SUBSCRIPTION_TYPE = 'UPDATE_USER_SUBSCRIPTION_TYPE'
export const UPDATE_USER_PROFILE_IMAGE = 'UPDATE_USER_PROFILE_IMAGE'

// Events
export const GET_EVENTS = 'GET_EVENTS'
export const GET_USER_EVENTS = 'GET_USER_EVENTS'
export const GET_EVENTS_PENDING = 'GET_EVENTS_PENDING'
export const GET_DISABLED_EVENT = 'GET_DISABLED_EVENT'
export const GET_EVENT = 'GET_EVENT'
export const GET_EVENT_PENDING = 'GET_EVENT_PENDING'
export const GET_USER_EVENT = 'GET_USER_EVENT'
export const GET_LATEST_EVENTS = 'GET_LATEST_EVENTS'
export const REMOVE_EVENT = 'REMOVE_EVENT'
export const UPDATE_EVENT = 'UPDATE_EVENT'
export const GET_EVENT_STORE = 'GET_EVENT_STORE'
export const GET_USER_EVENT_STORE = 'GET_USER_EVENT_STORE'

// Jobs
export const GET_JOBS = 'GET_JOBS'
export const GET_JOBS_PENDING = 'GET_JOBS_PENDING'
export const GET_DISABLED_JOB = 'GET_DISABLED_JOB'
export const GET_JOB_PENDING = 'GET_JOB_PENDING'
export const GET_USER_JOBS = 'GET_USER_JOBS'
export const GET_USER_JOB = 'GET_USER_JOB'
export const GET_LATEST_JOBS = 'GET_LATEST_JOBS'
export const GET_JOB = 'GET_JOB'
export const UPDATE_JOB = 'UPDATE_JOB'
export const REMOVE_JOB = 'REMOVE_JOB'
export const GET_JOB_STORE = 'GET_JOB_STORE'
export const GET_USER_JOB_STORE = 'GET_USER_JOB_STORE'

// Agencies
export const GET_USER_AGENCIES = 'GET_USER_AGENCIES'
export const GET_AGENCIES_PENDING = 'GET_AGENCIES_PENDING'
export const GET_DISABLED_AGENCY = 'GET_DISABLED_AGENCY'
export const GET_LATEST_AGENCIES = 'GET_LATEST_AGENCIES'
export const GET_AGENCY_PENDING = 'GET_AGENCY_PENDING'
export const GET_USER_AGENCY = 'GET_USER_AGENCY'
export const GET_AGENCIES = 'GET_AGENCIES'
export const GET_AGENCY = 'GET_AGENCY'
export const UPDATE_AGENCY = 'UPDATE_AGENCY'
export const REMOVE_AGENCY = 'REMOVE_AGENCY'
export const GET_AGENCY_STORE = 'GET_AGENCY_STORE'
export const GET_USER_AGENCY_STORE = 'GET_USER_AGENCY_STORE'

// Admin users
export const GET_USERS_LIST_PENDING = 'GET_USERS_LIST_PENDING'
export const GET_USERS_LIST = 'GET_USERS_LIST'
export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_USER_SUBSCRIPTION = 'UPDATE_USER_SUBSCRIPTION'

// Admin jobs
export const GET_ADMIN_JOBS_LIST = 'GET_ADMIN_JOBS_LIST'
export const GET_ADMIN_JOBS_PENDING = 'GET_ADMIN_JOBS_PENDING'
export const SET_JOB_STATUS = 'SET_JOB_STATUS'
export const MANAGE_JOB = 'MANAGE_JOB'
export const GET_ADMIN_JOB = 'GET_ADMIN_JOB'
export const GET_ADMIN_JOB_PENDING = 'GET_ADMIN_JOB_PENDING'
export const GET_ADMIN_JOB_STORE = 'GET_ADMIN_JOB_STORE'
export const UPDATE_ADMIN_JOB = 'UPDATE_ADMIN_JOB'
export const GET_DISABLED_ADMIN_JOB = 'GET_DISABLED_ADMIN_JOB'
export const REMOVE_ADMIN_JOB = 'REMOVE_ADMIN_JOB'

// Admin events
export const GET_ADMIN_EVENTS_LIST = 'GET_ADMIN_EVENTS_LIST'
export const GET_ADMIN_EVENTS_PENDING = 'GET_ADMIN_EVENTS_PENDING'
export const SET_EVENT_STATUS = 'SET_EVENT_STATUS'
export const MANAGE_EVENT = 'MANAGE_EVENT'
export const GET_ADMIN_EVENT = 'GET_ADMIN_EVENT'
export const GET_ADMIN_EVENT_PENDING = 'GET_ADMIN_EVENT_PENDING'
export const GET_ADMIN_EVENT_STORE = 'GET_ADMIN_EVENT_STORE'
export const UPDATE_ADMIN_EVENT = 'UPDATE_ADMIN_EVENT'
export const GET_DISABLED_ADMIN_EVENT = 'GET_DISABLED_ADMIN_EVENT'
export const REMOVE_ADMIN_EVENT = 'REMOVE_ADMIN_EVENT'

// Admin agencies
export const GET_ADMIN_AGENCIES_LIST = 'GET_ADMIN_AGENCIES_LIST'
export const GET_ADMIN_AGENCIES_PENDING = 'GET_ADMIN_AGENCIES_PENDING'
export const SET_AGENCY_STATUS = 'SET_AGENCY_STATUS'
export const MANAGE_AGENCY = 'MANAGE_AGENCY'
export const GET_ADMIN_AGENCY = 'GET_ADMIN_AGENCY'
export const GET_ADMIN_AGENCY_PENDING = 'GET_ADMIN_AGENCY_PENDING'
export const GET_ADMIN_AGENCY_STORE = 'GET_ADMIN_AGENCY_STORE'
export const UPDATE_ADMIN_AGENCY = 'UPDATE_ADMIN_AGENCY'
export const GET_DISABLED_ADMIN_AGENCY = 'GET_DISABLED_ADMIN_AGENCY'
export const REMOVE_ADMIN_AGENCY = 'REMOVE_ADMIN_AGENCY'

// Payments
export const GET_USER_PAYMENTS = 'GET_USER_PAYMENTS'
export const GET_PAYMENTS_PENDING = 'GET_PAYMENTS_PENDING'

// Admin payments
export const GET_ADMIN_USER_PAYMENTS = 'GET_ADMIN_USER_PAYMENTS'
export const GET_ADMIN_PAYMENTS_PENDING = 'GET_ADMIN_PAYMENTS_PENDING'
export const GET_DISABLED_ADMIN_PAYMENT = 'GET_DISABLED_ADMIN_PAYMENT'
export const UPDATE_ADMIN_PAYMENT = 'UPDATE_ADMIN_PAYMENT'
export const REMOVE_ADMIN_PAYMENT = 'REMOVE_ADMIN_PAYMENT'

// Subscription
export const GET_BACKDROP_LOADER = 'GET_BACKDROP_LOADER'
export const GET_DISABLED_BUTTON = 'GET_DISABLED_BUTTON'

// Logs
export const GET_LOGS_PENDING = 'GET_LOGS_PENDING'
export const GET_LOGS = 'GET_LOGS'
export const UPDATE_LOG = 'UPDATE_LOG'
