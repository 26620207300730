import Vue from 'vue'
import firebase from 'firebase/app'
import router from '../../router/router'
import { ERROR, GET_ADMIN_EVENTS_LIST, GET_ADMIN_EVENTS_PENDING, SET_EVENT_STATUS, MANAGE_EVENT, GET_ADMIN_EVENT, GET_ADMIN_EVENT_PENDING, GET_ADMIN_EVENT_STORE, UPDATE_ADMIN_EVENT, GET_DISABLED_ADMIN_EVENT, REMOVE_ADMIN_EVENT } from '../mutation_types'

var storageRef = firebase.storage()

const adminEvents = {
  state: {
    error: null,
    adminEventsList: null,
    adminEventsPending: false,
    adminEvent: null,
    adminEventPending: false,
    disabledAdminEvent: false
  },
  getters: {
    getAdminEventsError (state) {
      return state.error
    },
    getAdminEventsList (state) {
      return state.adminEventsList
    },
    getAdminEventsPending (state) {
      return state.adminEventsPending
    },
    getAdminEvent (state) {
      return state.adminEvent
    },
    getAdminEventPending (state) {
      return state.adminEventPending
    },
    getDisabledAdminEvent (state) {
      return state.disabledAdminEvent
    }
  },
  mutations: {
    [ERROR] (state, error) {
      state.error = error
    },
    [GET_ADMIN_EVENTS_LIST] (state, value) {
      state.adminEventsList = value
    },
    [GET_ADMIN_EVENTS_PENDING] (state, value) {
      state.adminEventsPending = value
    },
    [SET_EVENT_STATUS] (state, payload) {
      var updatedEvent = state.adminEventsList.find(event => event.id === payload.id)
      updatedEvent.status = payload.status
      updatedEvent.reason = payload.reason
    },
    [MANAGE_EVENT] (state, payload) {
      var upgradedEvent = state.adminEventsList.find(event => event.id === payload.id)
      upgradedEvent.ribbon = payload.ribbon
    },
    [GET_ADMIN_EVENT] (state, value) {
      state.adminEvent = value
    },
    [GET_ADMIN_EVENT_PENDING] (state, value) {
      state.adminEventPending = value
    },
    [GET_ADMIN_EVENT_STORE] (state, value) {
      state.adminEvent = [state.adminEventsList.find(jb => jb.id == value)]
    },
    [UPDATE_ADMIN_EVENT] (state, payload) {
      var formatedDate = payload.date
      var dd = formatedDate.getDate()

      var mm = formatedDate.getMonth() + 1
      var yyyy = formatedDate.getFullYear()
      if (dd < 10) {
        dd = '0' + dd
      }

      if (mm < 10) {
        mm = '0' + mm
      }
      formatedDate = dd + '-' + mm + '-' + yyyy

      var updatedEvent = state.adminEventsList.find(ev => ev.id === payload.eventId)
      updatedEvent.name = payload.name
      updatedEvent.type = payload.type
      updatedEvent.city = payload.city
      updatedEvent.country = payload.country
      updatedEvent.date = formatedDate
      updatedEvent.desc = payload.desc
      updatedEvent.email = payload.email
      updatedEvent.phone = payload.phone
      updatedEvent.website = payload.website
      updatedEvent.address = payload.address
      updatedEvent.lat = payload.lat
      updatedEvent.lng = payload.lng
      updatedEvent.status = 2
      if (payload.url !== undefined) {
        updatedEvent.url = payload.url
      }
    },
    [GET_DISABLED_ADMIN_EVENT] (state, value) {
      state.disabledAdminEvent = value
    },
    [REMOVE_ADMIN_EVENT] (state, value) {
      var deletedEvent = state.adminEventsList.find(jb => jb.id == value)
      state.adminEventsList.splice(state.adminEventsList.indexOf(deletedEvent), 1)
    }
  },
  actions: {
    getAdminEvents (context) {
      if (!context.state.adminEventsList) {
        context.commit(GET_ADMIN_EVENTS_PENDING, false)

        var adminEventsRef = firebase.database().ref('events')

        var adminEventsList = []

        adminEventsRef.on('child_added', snapshot => {
          adminEventsList.push({ ...snapshot.val(), id: snapshot.key })
        }, context.commit(GET_ADMIN_EVENTS_PENDING, true))

        context.commit(GET_ADMIN_EVENTS_LIST, adminEventsList)

        setTimeout(
          function () {
            context.commit(GET_ADMIN_EVENTS_PENDING, true)
          }, 3000
        )
      }
    },
    verifyEvent (context, payload) {
      var adminEventsRef = firebase.database().ref('events')

      payload.status = 1
      adminEventsRef.child(payload.id).update({ status: 1 })
        .then(() => {
          context.commit(SET_EVENT_STATUS, payload)
          Vue.swal({
            type: 'success',
            title: 'Success',
            text: 'Event verified',
            confirmButtonText: 'OK'
          })
        })
        .catch(error => {
          console.log(error.message)
          Vue.swal({
            type: 'error',
            title: 'Error occurred',
            text: 'Please try again later',
            confirmButtonText: 'OK'
          })
        })
    },
    setPendingEvent (context, payload) {
      var adminEventsRef = firebase.database().ref('events')

      payload.status = 2
      adminEventsRef.child(payload.id).update({ status: 2 })
        .then(() => {
          context.commit(SET_EVENT_STATUS, payload)
          Vue.swal({
            type: 'success',
            title: 'Success',
            text: 'Event set as pending',
            confirmButtonText: 'OK'
          })
        })
        .catch(error => {
          console.log(error.message)
          Vue.swal({
            type: 'error',
            title: 'Error occurred',
            text: 'Please try again later',
            confirmButtonText: 'OK'
          })
        })
    },
    denyEvent (context, payload) {
      var adminEventsRef = firebase.database().ref('events')

      payload.status = 3
      adminEventsRef.child(payload.id).update({ status: 3, reason: payload.reason })
        .then(() => {
          context.commit(SET_EVENT_STATUS, payload)
          Vue.swal({
            type: 'success',
            title: 'Success',
            text: 'Event denied',
            confirmButtonText: 'OK'
          })
        })
        .catch(error => {
          console.log(error.message)
          Vue.swal({
            type: 'error',
            title: 'Error occurred',
            text: 'Please try again later',
            confirmButtonText: 'OK'
          })
        })
    },
    upgradeEvent (context, payload) {
      var adminEventsRef = firebase.database().ref('events')

      payload.ribbon = true
      adminEventsRef.child(payload.id).update({ ribbon: true })
        .then(() => {
          context.commit(MANAGE_EVENT, payload)
          Vue.swal({
            type: 'success',
            title: 'Success',
            text: 'Event upgraded',
            confirmButtonText: 'OK'
          })
        })
        .catch(error => {
          console.log(error.message)
          Vue.swal({
            type: 'error',
            title: 'Error occurred',
            text: 'Please try again later',
            confirmButtonText: 'OK'
          })
        })
    },
    downgradeEvent (context, payload) {
      var adminEventsRef = firebase.database().ref('events')

      payload.ribbon = false
      adminEventsRef.child(payload.id).update({ ribbon: false })
        .then(() => {
          context.commit(MANAGE_EVENT, payload)
          Vue.swal({
            type: 'success',
            title: 'Success',
            text: 'Event downgraded',
            confirmButtonText: 'OK'
          })
        })
        .catch(error => {
          console.log(error.message)
          Vue.swal({
            type: 'error',
            title: 'Error occurred',
            text: 'Please try again later',
            confirmButtonText: 'OK'
          })
        })
    },
    getAdminEvent (context, payload) {
      context.commit(GET_ADMIN_EVENT_PENDING, false)
      if (!context.state.adminEvent) {
        var adminEventsRef = firebase.database().ref('events')

        var adminEvent = []

        adminEventsRef.child(payload.id).once('value', snapshot => {
          adminEvent.push({ ...snapshot.val() })
        }, context.commit(GET_ADMIN_EVENT_PENDING, true))
        context.commit(GET_ADMIN_EVENT, adminEvent)
      } else {
        context.commit(GET_ADMIN_EVENT_STORE, payload.id)
        context.commit(GET_ADMIN_EVENT_PENDING, true)
      }
    },
    updateAdminEventWithoutImage (context, payload) {
      var eventsRef = firebase.database().ref('events')
      var formatedDate = payload.date
      var dd = formatedDate.getDate()

      var mm = formatedDate.getMonth() + 1
      var yyyy = formatedDate.getFullYear()
      if (dd < 10) {
        dd = '0' + dd
      }

      if (mm < 10) {
        mm = '0' + mm
      }
      formatedDate = dd + '-' + mm + '-' + yyyy

      eventsRef.child(payload.eventId).update({ name: payload.name, type: payload.type, email: payload.email, phone: payload.phone, website: payload.website, address: payload.address, city: payload.city, country: payload.country, date: formatedDate, desc: payload.desc, lat: payload.lat, lng: payload.lng, status: 2 })
        .then(() => {
          context.commit(UPDATE_ADMIN_EVENT, payload)
          router.push('/admin-events')
        })
        .catch(function (error) {
          context.commit(ERROR, error.message)
          context.commit(GET_DISABLED_ADMIN_EVENT, false)
        })
    },
    updateAdminEventWithImage (context, payload) {
      var eventsRef = firebase.database().ref('events')
      storageRef.ref('events/' + payload.userId + '/' + payload.imageName).put(payload.image)
        .then(() => {
          // get new photo
          var downloadImg = storageRef.ref('events/' + payload.userId + '/' + payload.imageName)
          downloadImg.getDownloadURL().then((url) => {
            var formatedDate = payload.date
            var dd = formatedDate.getDate()

            var mm = formatedDate.getMonth() + 1
            var yyyy = formatedDate.getFullYear()
            if (dd < 10) {
              dd = '0' + dd
            }

            if (mm < 10) {
              mm = '0' + mm
            }
            formatedDate = dd + '-' + mm + '-' + yyyy

            payload.url = url

            eventsRef.child(payload.eventId).update({ name: payload.name, type: payload.type, email: payload.email, phone: payload.phone, website: payload.website, address: payload.address, city: payload.city, country: payload.country, date: formatedDate, desc: payload.desc, lat: payload.lat, lng: payload.lng, status: 2 })
              .then(() => {
                context.commit(UPDATE_ADMIN_EVENT, payload)
                router.push('/admin-events')
              })
              .catch(function (error) {
                context.commit(ERROR, error.message)
                context.commit(GET_DISABLED_ADMIN_EVENT, false)
              })
          })
        })
        .catch(function (error) {
          context.commit(ERROR, error.message)
          context.commit(GET_DISABLED_ADMIN_EVENT, false)
        })
    },
    clearDisabledAdminEvent (context) {
      context.commit(GET_DISABLED_ADMIN_EVENT, false)
    },
    setDisabledAdminEvent (context) {
      context.commit(GET_DISABLED_ADMIN_EVENT, true)
    },
    addAdminEvent (context, payload) {
      var user = firebase.auth().currentUser
      var eventsRef = firebase.database().ref('events')
      storageRef.ref('events/' + user.uid + '/' + payload.imageName).put(payload.image)
        .then(() => {
          // get new photo
          var downloadImg = storageRef.ref('events/' + user.uid + '/' + payload.imageName)
          downloadImg.getDownloadURL().then((url) => {
            var formatedDate = payload.date
            var dd = formatedDate.getDate()

            var mm = formatedDate.getMonth() + 1
            var yyyy = formatedDate.getFullYear()
            if (dd < 10) {
              dd = '0' + dd
            }

            if (mm < 10) {
              mm = '0' + mm
            }
            formatedDate = dd + '-' + mm + '-' + yyyy

            eventsRef.push().set({ name: payload.name, type: payload.type, email: payload.email, phone: payload.phone, website: payload.website, address: payload.address, city: payload.city, country: payload.country, date: formatedDate, desc: payload.desc, lat: payload.lat, lng: payload.lng, url: url, status: 2, user: user.uid, ribbon: false, image: payload.imageName })
              .then(() => {
                router.push('/admin-events')
                Vue.swal({
                  type: 'success',
                  title: 'New event added successfully',
                  confirmButtonText: 'OK'
                })
              })
              .catch(error => {
                context.commit(ERROR, error.message)
                context.commit(GET_DISABLED_ADMIN_EVENT, false)
              })
          })
        })
        .catch(error => {
          context.commit(ERROR, error.message)
          context.commit(GET_DISABLED_ADMIN_EVENT, false)
        })
    },
    deleteAdminEvent (context, payload) {
      var eventsRef = firebase.database().ref('events')

      storageRef.ref('events/' + payload.userId + '/' + payload.image).delete()
        .then(() => {
          eventsRef.child(payload.id).remove()
          context.commit(REMOVE_ADMIN_EVENT, payload.id)
          context.commit(GET_DISABLED_ADMIN_EVENT, false)
          Vue.swal({
            type: 'success',
            title: 'Event successfully deleted',
            confirmButtonText: 'OK'
          })
        }).catch(function (error) {
          context.commit(ERROR, error.message)
          context.commit(GET_DISABLED_ADMIN_EVENT, false)
        })
    }
  }
}

export default adminEvents
