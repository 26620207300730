<template>
    <div>
        <div v-if="navBackdrop" @click.prevent="hideNavigation" class="nav-backdrop"></div>
        <b-container fluid class="dektop-navigation">
            <b-container>
                <nav>
                    <b-row>
                        <b-col cols="2">
                            <router-link to="/" exact><img class="logo img-fluid" src="../assets/images/shared/logo.svg" alt="logo"></router-link>
                        </b-col>
                        <b-col cols="7">
                            <router-link to="/agencies" :class="this.$route.name == 'agency' ? 'active-link' : ''" class="dektop-navigation__link" active-class="active-link" exact><simple-line-icons icon="layers" size="small" color="#e91e63" />Agencies</router-link>
                            <router-link to="/events" :class="this.$route.name == 'event' ? 'active-link' : ''" class="dektop-navigation__link" active-class="active-link" exact><simple-line-icons icon="event" size="small" color="#e91e63" />Events</router-link>
                            <router-link to="/interviews" :class="this.$route.name == 'interview' ? 'active-link' : ''" class="dektop-navigation__link" active-class="active-link" exact><simple-line-icons icon="speech" size="small" color="#e91e63" />Interviews</router-link>
                            <router-link to="/map" class="dektop-navigation__link" active-class="active-link" exact><simple-line-icons icon="locationPin" size="small" color="#e91e63" />Map</router-link>
                            <router-link to="/jobs" :class="this.$route.name == 'job' ? 'active-link' : ''" class="dektop-navigation__link" active-class="active-link" exact><simple-line-icons icon="briefcase" size="small" color="#e91e63" />Jobs</router-link>
                            <router-link to="/statistics" class="dektop-navigation__link" active-class="active-link" exact><simple-line-icons icon="chart" size="small" color="#e91e63" />Statistics</router-link>
                            <!-- <b-dropdown text="Metrics" :class="this.$route.name == 'reports' ? 'active-link' : ''">
                                <b-dropdown-item><router-link role="menuitem" target="_self" to="/statistics" class="dektop-navigation__sub-link"><simple-line-icons icon="chart" size="small" color="#e91e63" />Statistics</router-link></b-dropdown-item>
                                <b-dropdown-item><router-link role="menuitem" target="_self" to="/reports" class="dektop-navigation__sub-link"><simple-line-icons icon="docs" size="small" color="#e91e63" />Reports</router-link></b-dropdown-item>
                            </b-dropdown> -->
                        </b-col>
                        <b-col cols="3" class="text-right">
                            <Spinner v-if="!getUserDataPending" class="dektop-navigation__loader" size="small" line-fg-color="#e91e63" />
                            <div v-else>
                                <div v-if="!getUserName">
                                    <button @click="signinRedirect" class="main-button main-button--signin"><simple-line-icons icon="login" size="small" color="#e91e63" />Sign in</button>
                                    <button @click="signupRedirect" class="main-button main-button--signup bubbly-button"><v-icon name="user" scale="1" />Sign up</button>
                                </div>
                                <div v-else>
                                    <router-link class="dektop-navigation__link" :class="(this.$route.name == 'profile' || this.$route.name == 'my-agencies' || this.$route.name == 'my-events' || this.$route.name == 'my-job-offers' || this.$route.name == 'payments' || this.$route.name == 'settings' || this.$route.name == 'add-agency' || this.$route.name == 'add-event' || this.$route.name == 'edit-event' || this.$route.name == 'add-job-offer' || this.$route.name == 'my-event' || this.$route.name == 'my-job' || this.$route.name == 'edit-job' || this.$route.name == 'my-agency' || this.$route.name == 'edit-agency') ? 'active-link' : ''" to="/profile"><simple-line-icons icon="user" size="small" color="#e91e63" />My profile</router-link>
                                    <button @click.prevent="signOut" type="button" class="dektop-navigation__link dektop-navigation__link--last"><simple-line-icons icon="logout" size="small" color="#e91e63" />Sign out</button>
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </nav>
            </b-container>
        </b-container>

        <div class="mobile-navbar">
            <b-col cols="12">
                <span @click="hideNavigation"><router-link to="/" exact><img class="logo img-fluid" src="../assets/images/shared/logo.svg" alt="logo"></router-link></span>
                <div @click.prevent='showNavigation'>
                    <div class="nav-icon" :class="showMobileMenu ? 'open' : ''">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
            </b-col>
        </div>
        <div class="mobile-naviagtion" :class="showMobileMenu ? 'mobile-menu-active' : ''">
            <div class="mobile-naviagtion__content">
                <div v-if="!getUserName">
                    <span @click="hideNavigation"><router-link to="/signin" class="mobile-naviagtion__link" active-class="active-link" exact>Sign in</router-link></span>
                    <span @click="hideNavigation"><router-link to="/signup" class="mobile-naviagtion__link" active-class="active-link" exact>Sign up</router-link></span>
                </div>
                <div v-else>
                    <span @click="hideNavigation"><router-link to="/profile" :class="(this.$route.name == 'profile' || this.$route.name == 'my-agencies' || this.$route.name == 'my-events' || this.$route.name == 'my-job-offers' || this.$route.name == 'payments' || this.$route.name == 'settings' || this.$route.name == 'add-agency' || this.$route.name == 'add-event' || this.$route.name == 'add-job-offer' || this.$route.name == 'my-event' || this.$route.name == 'my-job' || this.$route.name == 'edit-job' || this.$route.name == 'my-agency' ||this.$route.name == 'edit-agency') ? 'active-link' : ''" class="mobile-naviagtion__link" active-class="active-link" exact>My profile</router-link></span>
                </div>
                <span @click="hideNavigation"><router-link to="/agencies" :class="this.$route.name == 'agency' ? 'active-link' : ''" class="mobile-naviagtion__link" active-class="active-link" exact>Agencies</router-link></span>
                <span @click="hideNavigation"><router-link to="/events" :class="(this.$route.name == 'event' || this.$route.name == 'edit-event') ? 'active-link' : ''" class="mobile-naviagtion__link" active-class="active-link" exact>Events</router-link></span>
                <span @click="hideNavigation"><router-link to="/interviews" :class="this.$route.name == 'interview' ? 'active-link' : ''" class="mobile-naviagtion__link" active-class="active-link" exact>Interviews</router-link></span>
                <span @click="hideNavigation"><router-link to="/map" class="mobile-naviagtion__link" active-class="active-link" exact>Map</router-link></span>
                <span @click="hideNavigation"><router-link to="/jobs" :class="this.$route.name == 'job' ? 'active-link' : ''" class="mobile-naviagtion__link" active-class="active-link" exact>Jobs</router-link></span>
                <span @click="hideNavigation"><router-link to="/statistics" class="mobile-naviagtion__link" active-class="active-link" exact>Statistics</router-link></span>
                <!-- <span @click="hideNavigation"><router-link to="/reports" class="mobile-naviagtion__link" active-class="active-link" exact>Reports</router-link></span> -->
                <div v-if="getUserName">
                    <span @click="hideNavigation"><button @click.prevent="signOut" type="button"  class="mobile-naviagtion__link">Sign out</button></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import 'vue-awesome/icons/user'
import Icon from 'vue-awesome/components/Icon'
import SimpleLineIcons from 'vue-simple-line'
import router from '../router/router'
import { mapGetters } from 'vuex'
import Spinner from 'vue-simple-spinner'
export default {
  components: {
    'v-icon': Icon,
    SimpleLineIcons,
    Spinner
  },
  data: function () {
    return {
      showMobileMenu: false,
      test: null,
      type: '',
      navBackdrop: false
    }
  },
  methods: {
    showNavigation () {
      this.showMobileMenu = !this.showMobileMenu
      this.navBackdrop = !this.navBackdrop
    },
    hideNavigation () {
      this.showMobileMenu = false
      this.navBackdrop = false
    },
    signupRedirect () {
      setTimeout(function () {
        router.push('/signup')
      }, 700)
    },
    signinRedirect () {
      setTimeout(function () {
        router.push('/signin')
      }, 500)
    },
    signOut () {
      this.$store.dispatch('signOut')
    }
  },
  computed: {
    ...mapGetters([
      'getUserName',
      'getUserDataPending'
    ])
  },
  beforeCreate () {
    this.$store.dispatch('getUserData')
  }
}
</script>
