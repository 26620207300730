import Vue from 'vue'
import firebase from 'firebase/app'
import { GET_USERS_LIST_PENDING, GET_USERS_LIST, UPDATE_USER, UPDATE_USER_SUBSCRIPTION } from '../mutation_types'

const adminUsers = {
  state: {
    usersListPending: false,
    usersList: null
  },
  getters: {
    getUsersListPending (state) {
      return state.usersListPending
    },
    getUsersList (state) {
      return state.usersList
    }
  },
  mutations: {
    [GET_USERS_LIST_PENDING] (state, value) {
      state.usersListPending = value
    },
    [GET_USERS_LIST] (state, value) {
      state.usersList = value
    },
    [UPDATE_USER] (state, payload) {
      var updatedUser = state.usersList.find(user => user.id === payload.id)
      updatedUser.type = payload.type
    },
    [UPDATE_USER_SUBSCRIPTION] (state, payload) {
      var updatedUser = state.usersList.find(user => user.id === payload.id)
      updatedUser.subscription = payload.subscription
    }
  },
  actions: {
    getUsersList (context) {
      if (!context.state.usersList) {
        context.commit(GET_USERS_LIST_PENDING, false)

        var usersRef = firebase.database().ref('users')

        var usersList = []

        usersRef.on('child_added', snapshot => {
          usersList.push({ ...snapshot.val(), id: snapshot.key })
        }, context.commit(GET_USERS_LIST_PENDING, true))

        context.commit(GET_USERS_LIST, usersList)

        setTimeout(
          function () {
            context.commit(GET_USERS_LIST_PENDING, true)
          }, 3000
        )
      }
    },
    updateToFree (context, payload) {
      var usersRef = firebase.database().ref('users')

      payload.type = 1
      usersRef.child(payload.id).update({ type: 1 })
        .then(() => {
          context.commit(UPDATE_USER_SUBSCRIPTION, payload)
          Vue.swal({
            type: 'success',
            title: 'Success',
            text: 'User info updated',
            confirmButtonText: 'OK'
          })
        })
        .catch(error => {
          console.log(error.message)
          Vue.swal({
            type: 'error',
            title: 'Error occurred',
            text: 'Please try again later',
            confirmButtonText: 'OK'
          })
        })
    },
    updateToStandard (context, payload) {
      var usersRef = firebase.database().ref('users')

      payload.type = 2
      usersRef.child(payload.id).update({ type: 2 })
        .then(() => {
          context.commit(UPDATE_USER, payload)
          Vue.swal({
            type: 'success',
            title: 'Success',
            text: 'User info updated',
            confirmButtonText: 'OK'
          })
        })
        .catch(error => {
          console.log(error.message)
          Vue.swal({
            type: 'error',
            title: 'Error occurred',
            text: 'Please try again later',
            confirmButtonText: 'OK'
          })
        })
    },
    updateToPremium (context, payload) {
      var usersRef = firebase.database().ref('users')

      payload.type = 3
      usersRef.child(payload.id).update({ type: 3 })
        .then(() => {
          context.commit(UPDATE_USER, payload)
          Vue.swal({
            type: 'success',
            title: 'Success',
            text: 'User info updated',
            confirmButtonText: 'OK'
          })
        })
        .catch(error => {
          console.log(error.message)
          Vue.swal({
            type: 'error',
            title: 'Error occurred',
            text: 'Please try again later',
            confirmButtonText: 'OK'
          })
        })
    },
    updateToUltimate (context, payload) {
      var usersRef = firebase.database().ref('users')

      payload.type = 4
      usersRef.child(payload.id).update({ type: 4 })
        .then(() => {
          context.commit(UPDATE_USER, payload)
          Vue.swal({
            type: 'success',
            title: 'Success',
            text: 'User info updated',
            confirmButtonText: 'OK'
          })
        })
        .catch(error => {
          console.log(error.message)
          Vue.swal({
            type: 'error',
            title: 'Error occurred',
            text: 'Please try again later',
            confirmButtonText: 'OK'
          })
        })
    },
    subscriptionToActive (context, payload) {
      var usersRef = firebase.database().ref('users')

      payload.subscription = 1
      usersRef.child(payload.id).update({ subscription: 1 })
        .then(() => {
          context.commit(UPDATE_USER_SUBSCRIPTION, payload)
          Vue.swal({
            type: 'success',
            title: 'Success',
            text: 'User subscription updated',
            confirmButtonText: 'OK'
          })
        })
        .catch(error => {
          console.log(error.message)
          Vue.swal({
            type: 'error',
            title: 'Error occurred',
            text: 'Please try again later',
            confirmButtonText: 'OK'
          })
        })
    },
    subscriptionToPending (context, payload) {
      var usersRef = firebase.database().ref('users')

      payload.subscription = 2
      usersRef.child(payload.id).update({ subscription: 2 })
        .then(() => {
          context.commit(UPDATE_USER_SUBSCRIPTION, payload)
          Vue.swal({
            type: 'success',
            title: 'Success',
            text: 'User subscription updated',
            confirmButtonText: 'OK'
          })
        })
        .catch(error => {
          console.log(error.message)
          Vue.swal({
            type: 'error',
            title: 'Error occurred',
            text: 'Please try again later',
            confirmButtonText: 'OK'
          })
        })
    },
    subscriptionToInactive (context, payload) {
      var usersRef = firebase.database().ref('users')

      payload.subscription = 3
      usersRef.child(payload.id).update({ subscription: 3 })
        .then(() => {
          context.commit(UPDATE_USER_SUBSCRIPTION, payload)
          Vue.swal({
            type: 'success',
            title: 'Success',
            text: 'User subscription updated',
            confirmButtonText: 'OK'
          })
        })
        .catch(error => {
          console.log(error.message)
          Vue.swal({
            type: 'error',
            title: 'Error occurred',
            text: 'Please try again later',
            confirmButtonText: 'OK'
          })
        })
    },
    subscriptionToCancelled (context, payload) {
      var usersRef = firebase.database().ref('users')

      payload.subscription = 4
      usersRef.child(payload.id).update({ subscription: 4 })
        .then(() => {
          context.commit(UPDATE_USER_SUBSCRIPTION, payload)
          Vue.swal({
            type: 'success',
            title: 'Success',
            text: 'User subscription updated',
            confirmButtonText: 'OK'
          })
        })
        .catch(error => {
          console.log(error.message)
          Vue.swal({
            type: 'error',
            title: 'Error occurred',
            text: 'Please try again later',
            confirmButtonText: 'OK'
          })
        })
    },
    subscriptionToNone (context, payload) {
      var usersRef = firebase.database().ref('users')

      payload.subscription = null
      usersRef.child(payload.id).update({ subscription: null })
        .then(() => {
          context.commit(UPDATE_USER_SUBSCRIPTION, payload)
          Vue.swal({
            type: 'success',
            title: 'Success',
            text: 'User subscription updated',
            confirmButtonText: 'OK'
          })
        })
        .catch(error => {
          console.log(error.message)
          Vue.swal({
            type: 'error',
            title: 'Error occurred',
            text: 'Please try again later',
            confirmButtonText: 'OK'
          })
        })
    }
  }
}

export default adminUsers
