import 'babel-polyfill'
import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import BootstrapVue from 'bootstrap-vue'
import lineClamp from 'vue-line-clamp'
import VueProgressBar from 'vue-progressbar'
import Vuelidate from 'vuelidate'
import firebase from './firebase/firebase'
import { store } from './store/store'
import VueSweetalert2 from 'vue-sweetalert2'
import VuePaginate from 'vue-paginate'
import VueCookie from 'vue-cookie'
import VueLazyload from 'vue-lazyload'

// Map
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'

// Styles
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'pretty-checkbox/src/pretty-checkbox.scss'
import './styles/main.scss'

Vue.config.productionTip = false
Vue.config.devtools = false
Vue.config.debug = false
Vue.config.silent = true

Vue.use(BootstrapVue)
Vue.use(lineClamp, {
  importCss: true
})
Vue.use(VueProgressBar, {
  color: '#e91e63',
  failedColor: 'red',
  thickness: '2px',
  transition: {
    speed: '.5s',
    opacity: '0.6s',
    termination: 1000
  }
})
Vue.use(Vuelidate)
Vue.use(VueSweetalert2)
Vue.use(VuePaginate)
Vue.use(VueCookie)
Vue.use(VueLazyload)
delete L.Icon.Default.prototype._getIconUrl

L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

new Vue({
  firebase,
  store,
  router,
  render: h => h(App)
}).$mount('#app')

setTimeout(function () {
  // bubbly-button script
  var animateButton = function (e) {
    e.preventDefault
    // reset animation
    e.target.classList.remove('animate')

    e.target.classList.add('animate')
    setTimeout(function () {
      e.target.classList.remove('animate')
    }, 700)
  }

  var bubblyButtons = document.getElementsByClassName('bubbly-button')

  for (var i = 0; i < bubblyButtons.length; i++) {
    bubblyButtons[i].addEventListener('click', animateButton, false)
  }
}, 1500)
