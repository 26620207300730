import Vue from 'vue'
import firebase from 'firebase/app'
import router from '../../router/router'
import { ERROR, GET_JOBS, GET_JOBS_PENDING, GET_DISABLED_JOB, GET_JOB_PENDING, GET_USER_JOBS, GET_USER_JOB, GET_LATEST_JOBS, GET_JOB, UPDATE_JOB, REMOVE_JOB, GET_JOB_STORE, GET_USER_JOB_STORE } from '../mutation_types'

const jobs = {
  state: {
    jobsList: null,
    userJobsList: null,
    userJob: null,
    error: null,
    disabled: false,
    jobsPending: false,
    jobPending: false,
    disabledJob: false,
    latestJobsList: null,
    job: null
  },
  getters: {
    getJobError (state) {
      return state.error
    },
    getJobs (state) {
      return state.jobsList
    },
    getUserJobs (state) {
      return state.userJobsList
    },
    getUserJob (state) {
      return state.userJob
    },
    getJobsPending (state) {
      return state.jobsPending
    },
    getJobPending (state) {
      return state.jobPending
    },
    getDisabledJob (state) {
      return state.disabledJob
    },
    getLatestJobs (state) {
      return state.latestJobsList
    },
    getJob (state) {
      return state.job
    }
  },
  mutations: {
    [ERROR] (state, error) {
      state.error = error
    },
    [GET_JOBS] (state, value) {
      state.jobsList = value
    },
    [GET_USER_JOBS] (state, value) {
      state.userJobsList = value
    },
    [GET_USER_JOB] (state, value) {
      state.userJob = value
    },
    [GET_USER_JOB_STORE] (state, value) {
      state.userJob = [state.userJobsList.find(jb => jb.id == value)]
    },
    [GET_JOBS_PENDING] (state, value) {
      state.jobsPending = value
    },
    [GET_DISABLED_JOB] (state, value) {
      state.disabledJob = value
    },
    [GET_JOB_PENDING] (state, value) {
      state.jobPending = value
    },
    [GET_LATEST_JOBS] (state, value) {
      state.latestJobsList = value
    },
    [GET_JOB] (state, value) {
      state.job = value
    },
    [GET_JOB_STORE] (state, value) {
      state.job = [state.jobsList.find(jb => jb.id == value)]
    },
    [UPDATE_JOB] (state, payload) {
      var updatedJob = state.userJobsList.find(jb => jb.id === payload.jobId)

      updatedJob.position = payload.position
      updatedJob.type = payload.type
      updatedJob.company = payload.company
      updatedJob.city = payload.city
      updatedJob.country = payload.country
      updatedJob.email = payload.email
      updatedJob.phone = payload.phone
      updatedJob.website = payload.website
      updatedJob.apply = payload.apply
      updatedJob.desc = payload.desc
      updatedJob.qual = payload.qual
      updatedJob.offer = payload.offer
      updatedJob.method = payload.method
      updatedJob.status = 2
    },
    [REMOVE_JOB] (state, value) {
      var deletedJob = state.userJobsList.find(jb => jb.id == value)
      state.userJobsList.splice(state.userJobsList.indexOf(deletedJob), 1)
    }
  },
  actions: {
    getUserJobs (context) {
      if (!context.state.userJobsList) {
        context.commit(GET_JOBS_PENDING, false)
        var user = firebase.auth().currentUser
        var userJobsRef = firebase.database().ref('jobs').orderByChild('user').equalTo(user.uid)
        var jobsList = []

        userJobsRef.on('child_added', snapshot => {
          if (context.state.userJobsList !== null) {
            var stateJob = context.state.userJobsList.find(jb => jb.id == snapshot.key)
            if (stateJob !== undefined) {
              return false
            } else {
              jobsList.push({ ...snapshot.val(), id: snapshot.key })
            }
          } else {
            jobsList.push({ ...snapshot.val(), id: snapshot.key })
          }
        }, context.commit(GET_JOBS_PENDING, true))

        context.commit(GET_USER_JOBS, jobsList)

        setTimeout(
          function () {
            context.commit(GET_JOBS_PENDING, true)
          }, 3000
        )
      }
    },
    clearDisabledJob (context) {
      context.commit(GET_DISABLED_JOB, false)
    },
    addJob (context, payload) {
      var user = firebase.auth().currentUser
      var jobsRef = firebase.database().ref('jobs')

      jobsRef.push().set({ position: payload.position, type: payload.type, company: payload.company, phone: payload.phone, email: payload.email, website: payload.website, city: payload.city, country: payload.country, apply: payload.apply, desc: payload.desc, qual: payload.qual, offer: payload.offer, method: payload.method, status: 2, user: user.uid, ribbon: false })
        .then(() => {
          router.push('/my-job-offers')
          Vue.swal({
            type: 'success',
            title: 'New job offer added successfully',
            text: 'Now we have to verify content you want to publish. If the verification of content succeeds your job offer will appear in public directory.',
            confirmButtonText: 'OK'
          })
        })
        .catch(error => {
          context.commit(ERROR, error.message)
          context.commit(GET_DISABLED_JOB, false)
        })
    },
    setDisabledJob (context) {
      context.commit(GET_DISABLED_JOB, true)
    },
    getUserJob (context, payload) {
      context.commit(GET_JOB_PENDING, false)
      if (!context.state.userJobsList) {
        var user = firebase.auth().currentUser

        var jobsRef = firebase.database().ref('jobs')

        var job = []

        jobsRef.child(payload.id).once('value', snapshot => {
          job.push({ ...snapshot.val() })

          if (job[0].user != user.uid) {
            router.push('/401')
          }
        }, context.commit(GET_JOB_PENDING, true))
        context.commit(GET_USER_JOB, job)
      } else {
        context.commit(GET_USER_JOB_STORE, payload.id)
        context.commit(GET_JOB_PENDING, true)
      }
    },
    getLatestJobs (context) {
      if (!context.state.latestJobsList) {
        context.commit(GET_JOBS_PENDING, false)

        var jobsRef = firebase.database().ref('jobs').orderByChild('status').equalTo(1).limitToLast(4)

        var jobsList = []

        jobsRef.on('child_added', snapshot => {
          if (context.state.latestJobsList !== null) {
            var stateJob = context.state.latestJobsList.find(jb => jb.id == snapshot.key)
            if (stateJob !== undefined) {
              return false
            } else {
              jobsList.push({ ...snapshot.val(), id: snapshot.key })
            }
          } else {
            jobsList.push({ ...snapshot.val(), id: snapshot.key })
          }
        }, context.commit(GET_JOBS_PENDING, true))

        context.commit(GET_LATEST_JOBS, jobsList)

        setTimeout(
          function () {
            context.commit(GET_JOBS_PENDING, true)
          }, 3000
        )
      }
    },
    getJobs (context) {
      if (!context.state.jobsList) {
        context.commit(GET_JOBS_PENDING, false)

        var jobsRef = firebase.database().ref('jobs').orderByChild('status').equalTo(1)

        var jobsList = []

        jobsRef.on('child_added', snapshot => {
          if (context.state.jobsList !== null) {
            var stateJob = context.state.jobsList.find(jb => jb.id == snapshot.key)
            if (stateJob !== undefined) {
              return false
            } else {
              jobsList.push({ ...snapshot.val(), id: snapshot.key })
            }
          } else {
            jobsList.push({ ...snapshot.val(), id: snapshot.key })
          }
        }, context.commit(GET_JOBS_PENDING, true))

        context.commit(GET_JOBS, jobsList)

        setTimeout(
          function () {
            context.commit(GET_JOBS_PENDING, true)
          }, 3000
        )
      }
    },
    getJob (context, payload) {
      context.commit(GET_JOB_PENDING, false)
      if (!context.state.jobsList) {
        var jobsRef = firebase.database().ref('jobs')

        var job = []

        jobsRef.child(payload.id).once('value', snapshot => {
          job.push({ ...snapshot.val() })

          if (job[0].status != 1) {
            router.push('/jobs')
          }
        }, context.commit(GET_JOB_PENDING, true))
        context.commit(GET_JOB, job)
      } else {
        context.commit(GET_JOB_STORE, payload.id)
        context.commit(GET_JOB_PENDING, true)
      }
    },
    updateJob (context, payload) {
      var jobsRef = firebase.database().ref('jobs')

      jobsRef.child(payload.jobId).update({ position: payload.position, type: payload.type, company: payload.company, email: payload.email, phone: payload.phone, website: payload.website, city: payload.city, country: payload.country, apply: payload.apply, desc: payload.desc, qual: payload.qual, offer: payload.offer, method: payload.method, status: 2 })
        .then(() => {
          context.commit(UPDATE_JOB, payload)
          router.push('/my-job-offers')
          Vue.swal({
            type: 'success',
            title: 'Job offer successfully updated',
            text: 'Now we have to verify content you want to publish. If the verification of content succeeds your job offer will appear in public directory.',
            confirmButtonText: 'OK'
          })
        })
        .catch(function (error) {
          context.commit(ERROR, error.message)
          context.commit(GET_DISABLED_JOB, false)
        })
    },
    deleteJob (context, payload) {
      var jobsRef = firebase.database().ref('jobs')

      jobsRef.child(payload.id).remove()
      context.commit(REMOVE_JOB, payload.id)
      context.commit(GET_DISABLED_JOB, false)
      Vue.swal({
        type: 'success',
        title: 'Job offer successfully deleted',
        confirmButtonText: 'OK'
      })
    }
  }
}

export default jobs
