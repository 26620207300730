import Vue from 'vue'
import Router from 'vue-router'

import firebase from '../firebase/firebase'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
    },
    {
      path: '/agencies',
      name: 'agencies',
      component: () => import(/* webpackChunkName: "agencies" */ '../views/Agencies.vue')
    },
    {
      path: '/events',
      name: 'events',
      component: () => import(/* webpackChunkName: "events" */ '../views/Events.vue')
    },
    {
      path: '/event/:id',
      name: 'event',
      component: () => import(/* webpackChunkName: "event" */ '../views/Event.vue')
    },
    {
      path: '/agency/:id',
      name: 'agency',
      component: () => import(/* webpackChunkName: "agency" */ '../views/Agency.vue')
    },
    {
      path: '/signin',
      name: 'signin',
      component: () => import(/* webpackChunkName: "signin" */ '../views/SignIn.vue'),
      beforeEnter: (to, from, next) => {
        firebase.auth().onAuthStateChanged((user) => {
          if (user) {
            next({ name: 'profile' })
          } else {
            next()
          }
        })
      }
    },
    {
      path: '/signup',
      name: 'signup',
      component: () => import(/* webpackChunkName: "signup" */ '../views/SignUp.vue'),
      beforeEnter: (to, from, next) => {
        firebase.auth().onAuthStateChanged((user) => {
          if (user) {
            next({ name: 'profile' })
          } else {
            next()
          }
        })
      }
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      component: () => import(/* webpackChunkName: "resetPassword" */ '../views/ResetPassword.vue'),
      beforeEnter: (to, from, next) => {
        firebase.auth().onAuthStateChanged((user) => {
          if (user) {
            next({ name: 'profile' })
          } else {
            next()
          }
        })
      }
    },
    {
      path: '/reset-password-feedback',
      name: 'reset-password-feedback',
      component: () => import(/* webpackChunkName: "resetPasswordFeedback" */ '../views/ResetPasswordFeedback.vue'),
      beforeEnter: (to, from, next) => {
        if (from.name == 'reset-password') {
          next()
        } else {
          next({ name: '401' })
        }
      }
    },
    {
      path: '/interviews',
      name: 'interviews',
      component: () => import(/* webpackChunkName: "interviews" */ '../views/Interviews.vue')
    },
    {
      path: '/interview/:id',
      name: 'interview',
      component: () => import(/* webpackChunkName: "interview" */ '../views/Interview.vue')
    },
    {
      path: '/jobs',
      name: 'jobs',
      component: () => import(/* webpackChunkName: "jobs" */ '../views/Jobs.vue')
    },
    {
      path: '/job/:id',
      name: 'job',
      component: () => import(/* webpackChunkName: "job" */ '../views/Job.vue')
    },
    {
      path: '/map',
      name: 'map',
      component: () => import(/* webpackChunkName: "locationMap" */ '../views/LocationMap.vue')
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: () => import(/* webpackChunkName: "privacy" */ '../views/Privacy.vue')
    },
    {
      path: '/terms',
      name: 'terms',
      component: () => import(/* webpackChunkName: "terms" */ '../views/Terms.vue')
    },
    {
      path: '/advertise',
      name: 'advertise',
      component: () => import(/* webpackChunkName: "custom" */ '../views/Advertise.vue')
    },
    {
      path: '/cookies',
      name: 'cookies',
      component: () => import(/* webpackChunkName: "cookies" */ '../views/Cookies.vue')
    },
    {
      path: '/sponsorship',
      name: 'sponsorship',
      component: () => import(/* webpackChunkName: "sponsorship" */ '../views/Sponsorship.vue'),
      beforeEnter: (to, from, next) => {
        firebase.auth().onAuthStateChanged((user) => {
          next()
        })
      }
    },
    {
      path: '/404',
      name: '404',
      component: () => import(/* webpackChunkName: "_404" */ '../views/_404.vue')
    },
    {
      path: '/401',
      name: '401',
      component: () => import(/* webpackChunkName: "_401" */ '../views/_401.vue')
    },
    {
      path: '*',
      redirect: '/404'
    },
    {
      path: '/faq',
      name: 'faq',
      component: () => import(/* webpackChunkName: "faq" */ '../views/Faq.vue')
    },
    {
      path: '/reports',
      name: 'reports',
      component: () => import(/* webpackChunkName: "reports" */ '../views/Reports.vue')
    },
    {
      path: '/profile',
      name: 'profile',
      component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue'),
      beforeEnter: (to, from, next) => {
        firebase.auth().onAuthStateChanged((user) => {
          if (user) {
            next()
          } else {
            next({ name: 'home' })
          }
        })
      }
    },
    {
      path: '/my-agencies',
      name: 'my-agencies',
      component: () => import(/* webpackChunkName: "myAgencies" */ '../views/MyAgencies.vue'),
      beforeEnter: (to, from, next) => {
        firebase.auth().onAuthStateChanged((user) => {
          if (user) {
            next()
          } else {
            next({ name: 'home' })
          }
        })
      }
    },
    {
      path: '/my-agency/:id',
      name: 'my-agency',
      component: () => import(/* webpackChunkName: "myAgency" */ '../views/MyAgency.vue'),
      beforeEnter: (to, from, next) => {
        firebase.auth().onAuthStateChanged((user) => {
          if (user) {
            next()
          } else {
            next({ name: 'home' })
          }
        })
      }
    },
    {
      path: '/add-agency',
      name: 'add-agency',
      component: () => import(/* webpackChunkName: "addAgency" */ '../views/AddAgency.vue'),
      beforeEnter: (to, from, next) => {
        firebase.auth().onAuthStateChanged((user) => {
          if (user) {
            next()
          } else {
            next({ name: 'home' })
          }
        })
      }
    },
    {
      path: '/edit-agency/:id',
      name: 'edit-agency',
      component: () => import(/* webpackChunkName: "editAgency" */ '../views/EditAgency.vue'),
      beforeEnter: (to, from, next) => {
        firebase.auth().onAuthStateChanged((user) => {
          if (user) {
            next()
          } else {
            next({ name: 'home' })
          }
        })
      }
    },
    {
      path: '/my-events',
      name: 'my-events',
      component: () => import(/* webpackChunkName: "myEvents" */ '../views/MyEvents.vue'),
      beforeEnter: (to, from, next) => {
        firebase.auth().onAuthStateChanged((user) => {
          if (user) {
            next()
          } else {
            next({ name: 'home' })
          }
        })
      }
    },
    {
      path: '/my-event/:id',
      name: 'my-event',
      component: () => import(/* webpackChunkName: "myEvent" */ '../views/MyEvent.vue'),
      beforeEnter: (to, from, next) => {
        firebase.auth().onAuthStateChanged((user) => {
          if (user) {
            next()
          } else {
            next({ name: 'home' })
          }
        })
      }
    },
    {
      path: '/add-event',
      name: 'add-event',
      component: () => import(/* webpackChunkName: "addEvent" */ '../views/AddEvent.vue'),
      beforeEnter: (to, from, next) => {
        firebase.auth().onAuthStateChanged((user) => {
          if (user) {
            next()
          } else {
            next({ name: 'home' })
          }
        })
      }
    },
    {
      path: '/edit-event/:id',
      name: 'edit-event',
      component: () => import(/* webpackChunkName: "editEvent" */ '../views/EditEvent.vue'),
      beforeEnter: (to, from, next) => {
        firebase.auth().onAuthStateChanged((user) => {
          if (user) {
            next()
          } else {
            next({ name: 'home' })
          }
        })
      }
    },
    {
      path: '/my-job-offers',
      name: 'my-job-offers',
      component: () => import(/* webpackChunkName: "myJobOffers" */ '../views/MyJobOffers.vue'),
      beforeEnter: (to, from, next) => {
        firebase.auth().onAuthStateChanged((user) => {
          if (user) {
            next()
          } else {
            next({ name: 'home' })
          }
        })
      }
    },
    {
      path: '/my-job/:id',
      name: 'my-job',
      component: () => import(/* webpackChunkName: "myJob" */ '../views/MyJob.vue'),
      beforeEnter: (to, from, next) => {
        firebase.auth().onAuthStateChanged((user) => {
          if (user) {
            next()
          } else {
            next({ name: 'home' })
          }
        })
      }
    },
    {
      path: '/add-job-offer',
      name: 'add-job-offer',
      component: () => import(/* webpackChunkName: "addJobOffer" */ '../views/AddJobOffer.vue'),
      beforeEnter: (to, from, next) => {
        firebase.auth().onAuthStateChanged((user) => {
          if (user) {
            next()
          } else {
            next({ name: 'home' })
          }
        })
      }
    },
    {
      path: '/edit-job/:id',
      name: 'edit-job',
      component: () => import(/* webpackChunkName: "editJob" */ '../views/EditJob.vue'),
      beforeEnter: (to, from, next) => {
        firebase.auth().onAuthStateChanged((user) => {
          if (user) {
            next()
          } else {
            next({ name: 'home' })
          }
        })
      }
    },
    {
      path: '/payments',
      name: 'payments',
      component: () => import(/* webpackChunkName: "chunk1" */ '../views/Payments.vue'),
      beforeEnter: (to, from, next) => {
        firebase.auth().onAuthStateChanged((user) => {
          if (user) {
            next()
          } else {
            next({ name: 'home' })
          }
        })
      }
    },
    {
      path: '/subscription',
      name: 'subscription',
      component: () => import(/* webpackChunkName: "chunk2" */ '../views/Subscription.vue'),
      beforeEnter: (to, from, next) => {
        firebase.auth().onAuthStateChanged((user) => {
          if (user) {
            next()
          } else {
            next({ name: 'home' })
          }
        })
      }
    },
    {
      path: '/settings',
      name: 'settings',
      component: () => import(/* webpackChunkName: "settings" */ '../views/Settings.vue'),
      beforeEnter: (to, from, next) => {
        firebase.auth().onAuthStateChanged((user) => {
          if (user) {
            next()
          } else {
            next({ name: 'home' })
          }
        })
      }
    },
    {
      path: '/statistics',
      name: 'statistics',
      component: () => import(/* webpackChunkName: "statistics" */ '../views/Statistics.vue')
    },
    {
      path: '/115599',
      name: 'admin',
      component: () => import(/* webpackChunkName: "chunk3" */ '../views/Admin.vue'),
      beforeEnter: (to, from, next) => {
        firebase.auth().onAuthStateChanged((user) => {
          if (user && user.uid == process.env.VUE_APP_ADMIN) {
            next()
          } else {
            next({ name: 'home' })
          }
        })
      }
    },
    {
      path: '/admin-users',
      name: 'admin-users',
      component: () => import(/* webpackChunkName: "chunk4" */ '../views/AdminUsers.vue'),
      beforeEnter: (to, from, next) => {
        firebase.auth().onAuthStateChanged((user) => {
          if (user && user.uid == process.env.VUE_APP_ADMIN) {
            next()
          } else {
            next({ name: 'home' })
          }
        })
      }
    },
    {
      path: '/admin-agencies',
      name: 'admin-agencies',
      component: () => import(/* webpackChunkName: "chunk5" */ '../views/AdminAgencies.vue'),
      beforeEnter: (to, from, next) => {
        firebase.auth().onAuthStateChanged((user) => {
          if (user && user.uid == process.env.VUE_APP_ADMIN) {
            next()
          } else {
            next({ name: 'home' })
          }
        })
      }
    },
    {
      path: '/add-admin-agency',
      name: 'add-admin-agency',
      component: () => import(/* webpackChunkName: "chunk6" */ '../views/AddAdminAgency.vue'),
      beforeEnter: (to, from, next) => {
        firebase.auth().onAuthStateChanged((user) => {
          if (user && user.uid == process.env.VUE_APP_ADMIN) {
            next()
          } else {
            next({ name: 'home' })
          }
        })
      }
    },
    {
      path: '/edit-admin-agency/:id',
      name: 'edit-admin-agency',
      component: () => import(/* webpackChunkName: "chunk7" */ '../views/EditAdminAgency.vue'),
      beforeEnter: (to, from, next) => {
        firebase.auth().onAuthStateChanged((user) => {
          if (user && user.uid == process.env.VUE_APP_ADMIN) {
            next()
          } else {
            next({ name: 'home' })
          }
        })
      }
    },
    {
      path: '/admin-jobs',
      name: 'admin-jobs',
      component: () => import(/* webpackChunkName: "chunk8" */ '../views/AdminJobs.vue'),
      beforeEnter: (to, from, next) => {
        firebase.auth().onAuthStateChanged((user) => {
          if (user && user.uid == process.env.VUE_APP_ADMIN) {
            next()
          } else {
            next({ name: 'home' })
          }
        })
      }
    },
    {
      path: '/admin-job/:id',
      name: 'admin-job',
      component: () => import(/* webpackChunkName: "chunk9" */ '../views/AdminJob.vue'),
      beforeEnter: (to, from, next) => {
        firebase.auth().onAuthStateChanged((user) => {
          if (user && user.uid == process.env.VUE_APP_ADMIN) {
            next()
          } else {
            next({ name: 'home' })
          }
        })
      }
    },
    {
      path: '/edit-admin-job/:id',
      name: 'edit-admin-job',
      component: () => import(/* webpackChunkName: "chunk10" */ '../views/EditAdminJob.vue'),
      beforeEnter: (to, from, next) => {
        firebase.auth().onAuthStateChanged((user) => {
          if (user && user.uid == process.env.VUE_APP_ADMIN) {
            next()
          } else {
            next({ name: 'home' })
          }
        })
      }
    },
    {
      path: '/add-admin-job',
      name: 'add-admin-job',
      component: () => import(/* webpackChunkName: "chunk11" */ '../views/AddAdminJob.vue'),
      beforeEnter: (to, from, next) => {
        firebase.auth().onAuthStateChanged((user) => {
          if (user && user.uid == process.env.VUE_APP_ADMIN) {
            next()
          } else {
            next({ name: 'home' })
          }
        })
      }
    },
    {
      path: '/admin-events',
      name: 'admin-events',
      component: () => import(/* webpackChunkName: "chunk12" */ '../views/AdminEvents.vue'),
      beforeEnter: (to, from, next) => {
        firebase.auth().onAuthStateChanged((user) => {
          if (user && user.uid == process.env.VUE_APP_ADMIN) {
            next()
          } else {
            next({ name: 'home' })
          }
        })
      }
    },
    {
      path: '/admin-event/:id',
      name: 'admin-event',
      component: () => import(/* webpackChunkName: "chunk13" */ '../views/AdminEvent.vue'),
      beforeEnter: (to, from, next) => {
        firebase.auth().onAuthStateChanged((user) => {
          if (user && user.uid == process.env.VUE_APP_ADMIN) {
            next()
          } else {
            next({ name: 'home' })
          }
        })
      }
    },
    {
      path: '/add-admin-event',
      name: 'add-admin-event',
      component: () => import(/* webpackChunkName: "chunk14" */ '../views/AddAdminEvent.vue'),
      beforeEnter: (to, from, next) => {
        firebase.auth().onAuthStateChanged((user) => {
          if (user && user.uid == process.env.VUE_APP_ADMIN) {
            next()
          } else {
            next({ name: 'home' })
          }
        })
      }
    },
    {
      path: '/edit-admin-event/:id',
      name: 'edit-admin-event',
      component: () => import(/* webpackChunkName: "chunk15" */ '../views/EditAdminEvent.vue'),
      beforeEnter: (to, from, next) => {
        firebase.auth().onAuthStateChanged((user) => {
          if (user && user.uid == process.env.VUE_APP_ADMIN) {
            next()
          } else {
            next({ name: 'home' })
          }
        })
      }
    },
    {
      path: '/admin-agency/:id',
      name: 'admin-agency',
      component: () => import(/* webpackChunkName: "chunk16" */ '../views/AdminAgency.vue'),
      beforeEnter: (to, from, next) => {
        firebase.auth().onAuthStateChanged((user) => {
          if (user && user.uid == process.env.VUE_APP_ADMIN) {
            next()
          } else {
            next({ name: 'home' })
          }
        })
      }
    },
    {
      path: '/admin-payments',
      name: 'admin-payments',
      component: () => import(/* webpackChunkName: "chunk17" */ '../views/AdminPayments.vue'),
      beforeEnter: (to, from, next) => {
        firebase.auth().onAuthStateChanged((user) => {
          if (user && user.uid == process.env.VUE_APP_ADMIN) {
            next()
          } else {
            next({ name: 'home' })
          }
        })
      }
    },
    {
      path: '/admin-logs',
      name: 'admin-logs',
      component: () => import(/* webpackChunkName: "chunk18" */ '../views/AdminLogs.vue'),
      beforeEnter: (to, from, next) => {
        firebase.auth().onAuthStateChanged((user) => {
          if (user && user.uid == process.env.VUE_APP_ADMIN) {
            next()
          } else {
            next({ name: 'home' })
          }
        })
      }
    },
    {
      path: '/add-admin-payment',
      name: 'add-admin-payment',
      component: () => import(/* webpackChunkName: "chunk19" */ '../views/AddAdminPayment.vue'),
      beforeEnter: (to, from, next) => {
        firebase.auth().onAuthStateChanged((user) => {
          if (user && user.uid == process.env.VUE_APP_ADMIN) {
            next()
          } else {
            next({ name: 'home' })
          }
        })
      }
    },
    {
      path: '/edit-admin-payment/:id',
      name: 'edit-admin-payment',
      component: () => import(/* webpackChunkName: "chunk20" */ '../views/EditAdminPayment.vue'),
      beforeEnter: (to, from, next) => {
        firebase.auth().onAuthStateChanged((user) => {
          if (user && user.uid == process.env.VUE_APP_ADMIN) {
            next()
          } else {
            next({ name: 'home' })
          }
        })
      }
    },
    {
      path: '/94979c40-3599-4dbe-8821-e6306235b7fa',
      name: 'buy-main-advert',
      component: () => import(/* webpackChunkName: "chunk21" */ '../views/BuyMainAdvert.vue'),
      beforeEnter: (to, from, next) => {
        firebase.auth().onAuthStateChanged((user) => {
          if (user) {
            next()
          } else {
            next({ name: 'home' })
          }
        })
      }
    },
    {
      path: '/57b56a36-a7c9-42d1-8529-66874c4a9385',
      name: 'buy-aside-advert',
      component: () => import(/* webpackChunkName: "chunk22" */ '../views/BuyAsideAdvert.vue'),
      beforeEnter: (to, from, next) => {
        firebase.auth().onAuthStateChanged((user) => {
          if (user) {
            next()
          } else {
            next({ name: 'home' })
          }
        })
      }
    }

    // BLACKOUT
    // "rules": {".read": false,".write": false}  firebase hosting:disable
    // {
    //   path: "/maintenance-break",
    //   name: "maintenance-break",
    //   component: () => import(/* webpackChunkName: "maintenanceBreak" */ '../views/MaintenanceBreak.vue')
    // },
    // {
    //   path: "/",
    //   redirect: "/maintenance-break"
    // },
    // {
    //   path: "*",
    //   redirect: "/maintenance-break"
    // }
  ]
})
