import Vue from 'vue'
import firebase from 'firebase/app'
import router from '../../router/router'
import { ERROR, GET_ADMIN_AGENCIES_LIST, GET_ADMIN_AGENCIES_PENDING, SET_AGENCY_STATUS, MANAGE_AGENCY, GET_ADMIN_AGENCY, GET_ADMIN_AGENCY_PENDING, GET_ADMIN_AGENCY_STORE, UPDATE_ADMIN_AGENCY, GET_DISABLED_ADMIN_AGENCY, REMOVE_ADMIN_AGENCY } from '../mutation_types'

var storageRef = firebase.storage()

const adminAgencies = {
  state: {
    error: null,
    adminAgenciesList: null,
    adminAgenciesPending: false,
    adminAgency: null,
    adminAgencyPending: false,
    disabledAdminAgency: false
  },
  getters: {
    getAdminAgenciesError (state) {
      return state.error
    },
    getAdminAgenciesList (state) {
      return state.adminAgenciesList
    },
    getAdminAgenciesPending (state) {
      return state.adminAgenciesPending
    },
    getAdminAgency (state) {
      return state.adminAgency
    },
    getAdminAgencyPending (state) {
      return state.adminAgencyPending
    },
    getDisabledAdminAgency (state) {
      return state.disabledAdminAgency
    }
  },
  mutations: {
    [ERROR] (state, error) {
      state.error = error
    },
    [GET_ADMIN_AGENCIES_LIST] (state, value) {
      state.adminAgenciesList = value
    },
    [GET_ADMIN_AGENCIES_PENDING] (state, value) {
      state.adminAgenciesPending = value
    },
    [SET_AGENCY_STATUS] (state, payload) {
      var updatedAgency = state.adminAgenciesList.find(ag => ag.id === payload.id)
      updatedAgency.status = payload.status
      updatedAgency.reason = payload.reason
    },
    [MANAGE_AGENCY] (state, payload) {
      var upgradedAgency = state.adminAgenciesList.find(ag => ag.id === payload.id)
      upgradedAgency.rating = payload.rating
      if (payload.ribbon !== undefined) {
        upgradedAgency.ribbon = payload.ribbon
      }
      if (payload.stability !== undefined) {
        upgradedAgency.stability = payload.stability
      }
      if (payload.quality !== undefined) {
        upgradedAgency.quality = payload.quality
      }
      if (payload.reliability !== undefined) {
        upgradedAgency.reliability = payload.reliability
      }
      if (payload.flexibility !== undefined) {
        upgradedAgency.flexibility = payload.flexibility
      }
      if (payload.transparency !== undefined) {
        upgradedAgency.transparency = payload.transparency
      }
      if (payload.integrity !== undefined) {
        upgradedAgency.integrity = payload.integrity
      }
      if (payload.performance !== undefined) {
        upgradedAgency.performance = payload.performance
      }
      if (payload.awareness !== undefined) {
        upgradedAgency.awareness = payload.awareness
      }
      if (payload.awareness !== undefined) {
        upgradedAgency.awareness = payload.recognition
      }
      if (payload.leader !== undefined) {
        upgradedAgency.leader = payload.leader
      }
    },
    [GET_ADMIN_AGENCY] (state, value) {
      state.adminAgency = value
    },
    [GET_ADMIN_AGENCY_PENDING] (state, value) {
      state.adminAgencyPending = value
    },
    [GET_ADMIN_AGENCY_STORE] (state, value) {
      state.adminAgency = [state.adminAgenciesList.find(ag => ag.id == value)]
    },
    [UPDATE_ADMIN_AGENCY] (state, payload) {
      var foundedDate = payload.founded

      var foundedYear = foundedDate.getFullYear()

      var updatedAgency = state.adminAgenciesList.find(ag => ag.id === payload.agencyId)
      updatedAgency.name = payload.name
      updatedAgency.desc = payload.desc
      updatedAgency.city = payload.city
      updatedAgency.country = payload.country
      updatedAgency.founded = foundedYear
      updatedAgency.email = payload.email
      updatedAgency.address = payload.address
      updatedAgency.phone = payload.phone
      updatedAgency.website = payload.website
      updatedAgency.project = payload.project
      updatedAgency.employees = payload.employees
      updatedAgency.lat = payload.lat
      updatedAgency.lng = payload.lng
      updatedAgency.services.advertising = payload.advertising
      updatedAgency.services.digital = payload.digital
      updatedAgency.services.branding = payload.branding
      updatedAgency.services.social = payload.social
      updatedAgency.services.webDev = payload.webDev
      updatedAgency.services.mobile = payload.mobile
      updatedAgency.services.commerce = payload.commerce
      updatedAgency.services.data = payload.data
      updatedAgency.services.emailMarketing = payload.emailMarketing
      updatedAgency.services.eventMarketing = payload.eventMarketing
      updatedAgency.services.cloud = payload.cloud
      updatedAgency.services.seo = payload.seo
      updatedAgency.services.graphic = payload.graphic
      updatedAgency.services.print = payload.print
      updatedAgency.services.video = payload.video
      updatedAgency.status = 2
      if (payload.url !== undefined) {
        updatedAgency.url = payload.url
      }
    },
    [GET_DISABLED_ADMIN_AGENCY] (state, value) {
      state.disabledAdminAgency = value
    },
    [REMOVE_ADMIN_AGENCY] (state, value) {
      var deletedAgency = state.adminAgenciesList.find(ag => ag.id == value)
      state.adminAgenciesList.splice(state.adminAgenciesList.indexOf(deletedAgency), 1)
    }
  },
  actions: {
    getAdminAgencies (context) {
      if (!context.state.adminAgenciesList) {
        context.commit(GET_ADMIN_AGENCIES_PENDING, false)

        var adminAgenciesRef = firebase.database().ref('agencies')

        var adminAgenciesList = []

        adminAgenciesRef.on('child_added', snapshot => {
          adminAgenciesList.push({ ...snapshot.val(), id: snapshot.key })
        }, context.commit(GET_ADMIN_AGENCIES_PENDING, true))

        context.commit(GET_ADMIN_AGENCIES_LIST, adminAgenciesList)

        setTimeout(
          function () {
            context.commit(GET_ADMIN_AGENCIES_PENDING, true)
          }, 3000
        )
      }
    },
    verifyAgency (context, payload) {
      var adminAgenciesRef = firebase.database().ref('agencies')

      payload.status = 1
      adminAgenciesRef.child(payload.id).update({ status: 1 })
        .then(() => {
          context.commit(SET_AGENCY_STATUS, payload)
          Vue.swal({
            type: 'success',
            title: 'Success',
            text: 'Agency verified',
            confirmButtonText: 'OK'
          })
        })
        .catch(error => {
          console.log(error.message)
          Vue.swal({
            type: 'error',
            title: 'Error occurred',
            text: 'Please try again later',
            confirmButtonText: 'OK'
          })
        })
    },
    setPendingAgency (context, payload) {
      var adminAgenciesRef = firebase.database().ref('agencies')

      payload.status = 2
      adminAgenciesRef.child(payload.id).update({ status: 2 })
        .then(() => {
          context.commit(SET_AGENCY_STATUS, payload)
          Vue.swal({
            type: 'success',
            title: 'Success',
            text: 'Agency set as pending',
            confirmButtonText: 'OK'
          })
        })
        .catch(error => {
          console.log(error.message)
          Vue.swal({
            type: 'error',
            title: 'Error occurred',
            text: 'Please try again later',
            confirmButtonText: 'OK'
          })
        })
    },
    denyAgency (context, payload) {
      var adminAgenciesRef = firebase.database().ref('agencies')

      payload.status = 3
      adminAgenciesRef.child(payload.id).update({ status: 3, reason: payload.reason })
        .then(() => {
          context.commit(SET_AGENCY_STATUS, payload)
          Vue.swal({
            type: 'success',
            title: 'Success',
            text: 'Agency denied',
            confirmButtonText: 'OK'
          })
        })
        .catch(error => {
          console.log(error.message)
          Vue.swal({
            type: 'error',
            title: 'Error occurred',
            text: 'Please try again later',
            confirmButtonText: 'OK'
          })
        })
    },
    getAdminAgency (context, payload) {
      context.commit(GET_ADMIN_AGENCY_PENDING, false)
      if (!context.state.adminAgency) {
        var adminAgenciesRef = firebase.database().ref('agencies')

        var adminAgency = []

        adminAgenciesRef.child(payload.id).once('value', snapshot => {
          adminAgency.push({ ...snapshot.val(), id: snapshot.key })
        }, context.commit(GET_ADMIN_AGENCY_PENDING, true))
        context.commit(GET_ADMIN_AGENCY, adminAgency)
      } else {
        context.commit(GET_ADMIN_AGENCY_STORE, payload.id)
        context.commit(GET_ADMIN_AGENCY_PENDING, true)
      }
    },
    updateAdminAgencyWithoutImage (context, payload) {
      var agenciesRef = firebase.database().ref('agencies')

      var foundedDate = payload.founded
      var foundedYear = foundedDate.getFullYear()

      agenciesRef.child(payload.agencyId).update({ name: payload.name, employees: payload.employees, email: payload.email, phone: payload.phone, website: payload.website, address: payload.address, city: payload.city, country: payload.country, project: payload.project, founded: foundedYear, desc: payload.desc, lat: payload.lat, lng: payload.lng, services: { advertising: payload.advertising, digital: payload.digital, branding: payload.branding, social: payload.social, webDev: payload.webDev, mobile: payload.mobile, commerce: payload.commerce, data: payload.data, emailMarketing: payload.emailMarketing, eventMarketing: payload.eventMarketing, cloud: payload.cloud, seo: payload.seo, graphic: payload.graphic, print: payload.print, video: payload.video }, status: 2 })
        .then(() => {
          context.commit(UPDATE_ADMIN_AGENCY, payload)
          router.push('/admin-agencies')
          Vue.swal({
            type: 'success',
            title: 'Agency successfully updated',
            confirmButtonText: 'OK'
          })
        })
        .catch(error => {
          context.commit(ERROR, error.message)
          context.commit(GET_DISABLED_ADMIN_AGENCY, false)
        })
    },
    updateAdminAgencyWithImage (context, payload) {
      var agenciesRef = firebase.database().ref('agencies')
      storageRef.ref('agencies/' + payload.userId + '/' + payload.imageName).put(payload.image)
        .then(() => {
          // get new photo
          var downloadImg = storageRef.ref('agencies/' + payload.userId + '/' + payload.imageName)
          downloadImg.getDownloadURL().then((url) => {
            var foundedDate = payload.founded
            var foundedYear = foundedDate.getFullYear()

            payload.url = url

            agenciesRef.child(payload.agencyId).update({ name: payload.name, employees: payload.employees, email: payload.email, phone: payload.phone, website: payload.website, address: payload.address, city: payload.city, country: payload.country, project: payload.project, founded: foundedYear, desc: payload.desc, lat: payload.lat, lng: payload.lng, services: { advertising: payload.advertising, digital: payload.digital, branding: payload.branding, social: payload.social, webDev: payload.webDev, mobile: payload.mobile, commerce: payload.commerce, data: payload.data, emailMarketing: payload.emailMarketing, eventMarketing: payload.eventMarketing, cloud: payload.cloud, seo: payload.seo, graphic: payload.graphic, print: payload.print, video: payload.video }, status: 2 })
              .then(() => {
                context.commit(UPDATE_ADMIN_AGENCY, payload)
                router.push('/admin-agencies')
                Vue.swal({
                  type: 'success',
                  title: 'Agency successfully updated',
                  confirmButtonText: 'OK'
                })
              })
              .catch(error => {
                context.commit(ERROR, error.message)
                context.commit(GET_DISABLED_ADMIN_AGENCY, false)
              })
          })
        })
        .catch(error => {
          context.commit(ERROR, error.message)
          context.commit(GET_DISABLED_ADMIN_AGENCY, false)
        })
    },
    clearDisabledAdminAgency (context) {
      context.commit(GET_DISABLED_ADMIN_AGENCY, false)
    },
    setDisabledAdminAgency (context) {
      context.commit(GET_DISABLED_ADMIN_AGENCY, true)
    },
    addAdminAgency (context, payload) {
      var user = firebase.auth().currentUser
      var agenciesRef = firebase.database().ref('agencies')
      storageRef.ref('agencies/' + user.uid + '/' + payload.imageName).put(payload.image)
        .then(() => {
          // get new photo
          var downloadImg = storageRef.ref('agencies/' + user.uid + '/' + payload.imageName)
          downloadImg.getDownloadURL().then((url) => {
            var foundedDate = payload.founded

            var foundedYear = foundedDate.getFullYear()

            agenciesRef.push().set({ name: payload.name, employees: payload.employees, email: payload.email, phone: payload.phone, website: payload.website, address: payload.address, city: payload.city, country: payload.country, project: payload.project, founded: foundedYear, desc: payload.desc, lat: payload.lat, lng: payload.lng, url: url, services: { advertising: payload.advertising, digital: payload.digital, branding: payload.branding, social: payload.social, webDev: payload.webDev, mobile: payload.mobile, commerce: payload.commerce, data: payload.data, emailMarketing: payload.emailMarketing, eventMarketing: payload.eventMarketing, cloud: payload.cloud, seo: payload.seo, graphic: payload.graphic, print: payload.print, video: payload.video }, status: 2, user: user.uid, ribbon: false, image: payload.imageName })
              .then(() => {
                router.push('/admin-agencies')
                Vue.swal({
                  type: 'success',
                  title: 'New agency added successfully',
                  confirmButtonText: 'OK'
                })
              })
              .catch(error => {
                context.commit(ERROR, error.message)
                context.commit(GET_DISABLED_ADMIN_AGENCY, false)
              })
          })
        })
        .catch(error => {
          context.commit(ERROR, error.message)
          context.commit(GET_DISABLED_ADMIN_AGENCY, false)
        })
    },
    deleteAdminAgency (context, payload) {
      var agenciesRef = firebase.database().ref('agencies')

      storageRef.ref('agencies/' + payload.userId + '/' + payload.image).delete()
        .then(() => {
          agenciesRef.child(payload.id).remove()
          context.commit(REMOVE_ADMIN_AGENCY, payload.id)
          context.commit(GET_DISABLED_ADMIN_AGENCY, false)
          Vue.swal({
            type: 'success',
            title: 'Agency successfully deleted',
            confirmButtonText: 'OK'
          })
        }).catch(function (error) {
          context.commit(ERROR, error.message)
          context.commit(GET_DISABLED_ADMIN_AGENCY, false)
        })
    },
    downgradeToFree (context, payload) {
      var adminAgenciesRef = firebase.database().ref('agencies')

      payload.ribbon = false
      payload.rating = null
      payload.stability = null
      payload.quality = null
      payload.reliability = null
      payload.flexibility = null
      payload.transparency = null
      payload.integrity = null
      payload.performance = null
      payload.awareness = null
      payload.recognition = null
      payload.leader = false

      adminAgenciesRef.child(payload.id).update({ ribbon: false, rating: null, stability: payload.stability, quality: payload.quality, reliability: payload.reliability, flexibility: payload.flexibility, transparency: payload.transparency, integrity: payload.integrity, performance: payload.performance, awareness: payload.awareness, recognition: payload.recognition, leader: payload.leader })
        .then(() => {
          context.commit(MANAGE_AGENCY, payload)
          Vue.swal({
            type: 'success',
            title: 'Success',
            text: 'Agency downgraded to FREE',
            confirmButtonText: 'OK'
          })
        })
        .catch(error => {
          console.log(error.message)
          Vue.swal({
            type: 'error',
            title: 'Error occurred',
            text: 'Please try again later',
            confirmButtonText: 'OK'
          })
        })
    },
    upgradeToStandard (context, payload) {
      var adminAgenciesRef = firebase.database().ref('agencies')

      payload.ribbon = true
      payload.rating = Number(((Math.random() * (3.50 - 3)) + 3).toFixed(2))
      payload.stability = Math.floor(Math.random() * (95 - 70 + 1)) + 70
      payload.quality = Math.floor(Math.random() * (95 - 70 + 1)) + 70
      payload.reliability = Math.floor(Math.random() * (95 - 70 + 1)) + 70
      payload.flexibility = Math.floor(Math.random() * (95 - 70 + 1)) + 70
      payload.transparency = Math.floor(Math.random() * (95 - 70 + 1)) + 70
      payload.integrity = Math.floor(Math.random() * (95 - 70 + 1)) + 70
      payload.performance = { strong: [Math.floor(Math.random() * (60 - 45 + 1)) + 45, Math.floor(Math.random() * (60 - 45 + 1)) + 45, Math.floor(Math.random() * (60 - 45 + 1)) + 45, Math.floor(Math.random() * (60 - 45 + 1)) + 45, Math.floor(Math.random() * (60 - 45 + 1)) + 45], high: [Math.floor(Math.random() * (45 - 35 + 1)) + 35, Math.floor(Math.random() * (45 - 35 + 1)) + 35, Math.floor(Math.random() * (45 - 35 + 1)) + 35, Math.floor(Math.random() * (45 - 35 + 1)) + 35, Math.floor(Math.random() * (45 - 35 + 1)) + 35], stable: [Math.floor(Math.random() * (15 - 9 + 1)) + 9, Math.floor(Math.random() * (15 - 9 + 1)) + 9, Math.floor(Math.random() * (15 - 9 + 1)) + 9, Math.floor(Math.random() * (15 - 9 + 1)) + 9, Math.floor(Math.random() * (15 - 9 + 1)) + 9] }
      payload.awareness = [Math.floor(Math.random() * (12 - 9 + 1)) + 9, Math.floor(Math.random() * (15 - 10 + 1)) + 10, Math.floor(Math.random() * (19 - 12 + 1)) + 12, Math.floor(Math.random() * (20 - 15 + 1)) + 15, Math.floor(Math.random() * (20 - 15 + 1)) + 15, Math.floor(Math.random() * (22 - 19 + 1)) + 15, Math.floor(Math.random() * (25 - 19 + 1)) + 19]
      payload.recognition = [Math.floor(Math.random() * (25 - 23 + 1)) + 23, Math.floor(Math.random() * (27 - 25 + 1)) + 25, Math.floor(Math.random() * (33 - 25 + 1)) + 25, Math.floor(Math.random() * (33 - 29 + 1)) + 29, Math.floor(Math.random() * (35 - 30 + 1)) + 30, Math.floor(Math.random() * (35 - 30 + 1)) + 30, Math.floor(Math.random() * (39 - 32 + 1)) + 32]
      payload.leader = false

      adminAgenciesRef.child(payload.id).update({ ribbon: true, rating: payload.rating, stability: payload.stability, quality: payload.quality, reliability: payload.reliability, flexibility: payload.flexibility, transparency: payload.transparency, integrity: payload.integrity, performance: payload.performance, awareness: payload.awareness, recognition: payload.recognition, leader: payload.leader })
        .then(() => {
          context.commit(MANAGE_AGENCY, payload)
          Vue.swal({
            type: 'success',
            title: 'Success',
            text: 'Agency upgraded to STANDARD',
            confirmButtonText: 'OK'
          })
        })
        .catch(error => {
          console.log(error.message)
          Vue.swal({
            type: 'error',
            title: 'Error occurred',
            text: 'Please try again later',
            confirmButtonText: 'OK'
          })
        })
    },
    upgradeToPremium (context, payload) {
      var adminAgenciesRef = firebase.database().ref('agencies')

      payload.rating = Number(((Math.random() * (4.50 - 4.10)) + 4.10).toFixed(2))
      payload.leader = false

      adminAgenciesRef.child(payload.id).update({ rating: payload.rating, leader: false })
        .then(() => {
          context.commit(MANAGE_AGENCY, payload)
          Vue.swal({
            type: 'success',
            title: 'Success',
            text: 'Agency upgraded to STANDARD',
            confirmButtonText: 'OK'
          })
        })
        .catch(error => {
          console.log(error.message)
          Vue.swal({
            type: 'error',
            title: 'Error occurred',
            text: 'Please try again later',
            confirmButtonText: 'OK'
          })
        })
    },
    upgradeToUltimate (context, payload) {
      var adminAgenciesRef = firebase.database().ref('agencies')

      payload.rating = Number(((Math.random() * (4.95 - 4.70)) + 4.70).toFixed(2))
      payload.leader = true

      adminAgenciesRef.child(payload.id).update({ rating: payload.rating, leader: true })
        .then(() => {
          context.commit(MANAGE_AGENCY, payload)
          Vue.swal({
            type: 'success',
            title: 'Success',
            text: 'Agency upgraded to STANDARD',
            confirmButtonText: 'OK'
          })
        })
        .catch(error => {
          console.log(error.message)
          Vue.swal({
            type: 'error',
            title: 'Error occurred',
            text: 'Please try again later',
            confirmButtonText: 'OK'
          })
        })
    }
  }
}

export default adminAgencies
