import Vue from 'vue'
import firebase from 'firebase/app'
import router from '../../router/router'
import { ERROR, GET_USER_AGENCIES, GET_AGENCIES_PENDING, GET_DISABLED_AGENCY, GET_LATEST_AGENCIES, GET_AGENCY_PENDING, GET_USER_AGENCY, GET_AGENCIES, GET_AGENCY, UPDATE_AGENCY, REMOVE_AGENCY, GET_AGENCY_STORE, GET_USER_AGENCY_STORE } from '../mutation_types'

var storageRef = firebase.storage()

const agencies = {
  state: {
    agenciesList: null,
    latestAgenciesList: null,
    userAgenciesList: null,
    error: null,
    disabled: false,
    agenciesPending: false,
    disabledAgency: false,
    agency: null,
    userAgency: null,
    agencyPending: false
  },
  getters: {
    getAgencyError (state) {
      return state.error
    },
    getUserAgencies (state) {
      return state.userAgenciesList
    },
    getDisabledAgency (state) {
      return state.disabledAgency
    },
    getAgenciesPending (state) {
      return state.agenciesPending
    },
    getLatestAgencies (state) {
      return state.latestAgenciesList
    },
    getUserAgency (state) {
      return state.userAgency
    },
    getAgencyPending (state) {
      return state.agencyPending
    },
    getAgencies (state) {
      return state.agenciesList
    },
    getAgency (state) {
      return state.agency
    }
  },
  mutations: {
    [ERROR] (state, error) {
      state.error = error
    },
    [GET_USER_AGENCIES] (state, value) {
      state.userAgenciesList = value
    },
    [GET_AGENCIES_PENDING] (state, value) {
      state.agenciesPending = value
    },
    [GET_DISABLED_AGENCY] (state, value) {
      state.disabledAgency = value
    },
    [GET_LATEST_AGENCIES] (state, value) {
      state.latestAgenciesList = value
    },
    [GET_USER_AGENCY] (state, value) {
      state.userAgency = value
    },
    [GET_USER_AGENCY_STORE] (state, value) {
      state.userAgency = [state.userAgenciesList.find(ag => ag.id == value)]
    },
    [GET_AGENCY_PENDING] (state, value) {
      state.agencyPending = value
    },
    [GET_AGENCIES] (state, value) {
      state.agenciesList = value
    },
    [GET_AGENCY] (state, value) {
      state.agency = value
    },
    [GET_AGENCY_STORE] (state, value) {
      state.agency = [state.agenciesList.find(ag => ag.id == value)]
    },
    [UPDATE_AGENCY] (state, payload) {
      var foundedDate = payload.founded

      var foundedYear = foundedDate.getFullYear()

      var updatedAgency = state.userAgenciesList.find(ag => ag.id === payload.agencyId)
      updatedAgency.name = payload.name
      updatedAgency.desc = payload.desc
      updatedAgency.city = payload.city
      updatedAgency.address = payload.address
      updatedAgency.country = payload.country
      updatedAgency.founded = foundedYear
      updatedAgency.email = payload.email
      updatedAgency.phone = payload.phone
      updatedAgency.website = payload.website
      updatedAgency.project = payload.project
      updatedAgency.employees = payload.employees
      updatedAgency.lat = payload.lat
      updatedAgency.lng = payload.lng
      updatedAgency.services.advertising = payload.advertising
      updatedAgency.services.digital = payload.digital
      updatedAgency.services.branding = payload.branding
      updatedAgency.services.social = payload.social
      updatedAgency.services.webDev = payload.webDev
      updatedAgency.services.mobile = payload.mobile
      updatedAgency.services.commerce = payload.commerce
      updatedAgency.services.data = payload.data
      updatedAgency.services.emailMarketing = payload.emailMarketing
      updatedAgency.services.eventMarketing = payload.eventMarketing
      updatedAgency.services.cloud = payload.cloud
      updatedAgency.services.seo = payload.seo
      updatedAgency.services.graphic = payload.graphic
      updatedAgency.services.print = payload.print
      updatedAgency.services.video = payload.video
      updatedAgency.status = 2
      if (payload.url !== undefined) {
        updatedAgency.url = payload.url
      }
    },
    [REMOVE_AGENCY] (state, value) {
      var deletedAgency = state.userAgenciesList.find(ag => ag.id == value)
      state.userAgenciesList.splice(state.userAgenciesList.indexOf(deletedAgency), 1)
    }
  },
  actions: {
    getUserAgencies (context) {
      if (!context.state.userAgenciesList) {
        context.commit(GET_AGENCIES_PENDING, false)
        var user = firebase.auth().currentUser
        var userAgenciesRef = firebase.database().ref('agencies').orderByChild('user').equalTo(user.uid).limitToFirst(1)
        var agenciesList = []

        userAgenciesRef.on('child_added', snapshot => {
          if (context.state.userAgenciesList !== null) {
            var stateAgency = context.state.userAgenciesList.find(ag => ag.id == snapshot.key)
            if (stateAgency !== undefined) {
              return false
            } else {
              agenciesList.push({ ...snapshot.val(), id: snapshot.key })
            }
          } else {
            agenciesList.push({ ...snapshot.val(), id: snapshot.key })
          }
        }, context.commit(GET_AGENCIES_PENDING, true))

        context.commit(GET_USER_AGENCIES, agenciesList)

        setTimeout(
          function () {
            context.commit(GET_AGENCIES_PENDING, true)
          }, 3000
        )
      }
    },
    clearDisabledAgency (context) {
      context.commit(GET_DISABLED_AGENCY, false)
    },
    setDisabledAgency (context) {
      context.commit(GET_DISABLED_AGENCY, true)
    },
    addAgency (context, payload) {
      var user = firebase.auth().currentUser
      var agenciesRef = firebase.database().ref('agencies')
      storageRef.ref('agencies/' + user.uid + '/' + payload.imageName).put(payload.image)
        .then(() => {
          // get new photo
          var downloadImg = storageRef.ref('agencies/' + user.uid + '/' + payload.imageName)
          downloadImg.getDownloadURL().then((url) => {
            var foundedDate = payload.founded

            var foundedYear = foundedDate.getFullYear()

            agenciesRef.push().set({ name: payload.name, employees: payload.employees, email: payload.email, phone: payload.phone, website: payload.website, address: payload.address, city: payload.city, country: payload.country, project: payload.project, founded: foundedYear, desc: payload.desc, lat: payload.lat, lng: payload.lng, url: url, services: { advertising: payload.advertising, digital: payload.digital, branding: payload.branding, social: payload.social, webDev: payload.webDev, mobile: payload.mobile, commerce: payload.commerce, data: payload.data, emailMarketing: payload.emailMarketing, eventMarketing: payload.eventMarketing, cloud: payload.cloud, seo: payload.seo, graphic: payload.graphic, print: payload.print, video: payload.video }, status: 2, user: user.uid, ribbon: false, image: payload.imageName })
              .then(() => {
                router.push('/my-agencies')
                Vue.swal({
                  type: 'success',
                  title: 'New agency added successfully',
                  text: 'Now we have to verify content you want to publish. If the verification of content succeeds your agency will appear in public directory.',
                  confirmButtonText: 'OK'
                })
              })
              .catch(error => {
                context.commit(ERROR, error.message)
                context.commit(GET_DISABLED_AGENCY, false)
              })
          })
        })
        .catch(error => {
          context.commit(ERROR, error.message)
          context.commit(GET_DISABLED_AGENCY, false)
        })
    },
    checkUserAgencies (context) {
      context.commit(GET_AGENCIES_PENDING, false)
      var user = firebase.auth().currentUser
      var userAgenciesRef = firebase.database().ref('agencies').orderByChild('user').equalTo(user.uid).limitToFirst(1)
      var agenciesList = []

      userAgenciesRef.on('child_added', snapshot => {
        agenciesList.push({ ...snapshot.val(), id: snapshot.key }), context.commit(GET_AGENCIES_PENDING, true)

        if (agenciesList.length !== 0) {
          router.push('/my-agencies')
        }
      })

      setTimeout(
        function () {
          context.commit(GET_AGENCIES_PENDING, true)
        }, 3000
      )
    },
    getLatestAgencies (context) {
      if (!context.state.latestAgenciesList) {
        context.commit(GET_AGENCIES_PENDING, false)

        var userAgenciesRef = firebase.database().ref('agencies').orderByChild('status').equalTo(1).limitToLast(3)

        var agenciesList = []

        userAgenciesRef.on('child_added', snapshot => {
          if (context.state.latestAgenciesList !== null) {
            var stateAgency = context.state.latestAgenciesList.find(ag => ag.id == snapshot.key)
            if (stateAgency !== undefined) {
              return false
            } else {
              agenciesList.push({ ...snapshot.val(), id: snapshot.key })
            }
          } else {
            agenciesList.push({ ...snapshot.val(), id: snapshot.key })
          }
        }, context.commit(GET_AGENCIES_PENDING, true))

        context.commit(GET_LATEST_AGENCIES, agenciesList)

        setTimeout(
          function () {
            context.commit(GET_AGENCIES_PENDING, true)
          }, 3000
        )
      }
    },
    getUserAgency (context, payload) {
      context.commit(GET_AGENCY_PENDING, false)
      if (!context.state.userAgenciesList) {
        var user = firebase.auth().currentUser

        var userAgenciesRef = firebase.database().ref('agencies')

        var agency = []

        userAgenciesRef.child(payload.id).once('value', snapshot => {
          agency.push({ ...snapshot.val(), id: snapshot.key })

          if (agency[0].user != user.uid) {
            router.push('/401')
          }
        }, context.commit(GET_AGENCY_PENDING, true))

        context.commit(GET_USER_AGENCY, agency)
      } else {
        context.commit(GET_USER_AGENCY_STORE, payload.id)
        context.commit(GET_AGENCY_PENDING, true)
      }
    },
    getAgencies (context) {
      if (!context.state.agenciesList) {
        context.commit(GET_AGENCIES_PENDING, false)

        var agenciesRef = firebase.database().ref('agencies').orderByChild('status').equalTo(1)

        var agenciesList = []

        agenciesRef.on('child_added', snapshot => {
          if (context.state.agenciesList !== null) {
            var stateAgency = context.state.agenciesList.find(ag => ag.id == snapshot.key)
            if (stateAgency !== undefined) {
              return false
            } else {
              agenciesList.push({ ...snapshot.val(), id: snapshot.key })
            }
          } else {
            agenciesList.push({ ...snapshot.val(), id: snapshot.key })
          }
        }, context.commit(GET_AGENCIES_PENDING, true))

        context.commit(GET_AGENCIES, agenciesList)

        setTimeout(
          function () {
            context.commit(GET_AGENCIES_PENDING, true)
          }, 3000
        )
      }
    },
    getAgency (context, payload) {
      context.commit(GET_AGENCY_PENDING, false)
      if (!context.state.agenciesList) {
        var agenciesRef = firebase.database().ref('agencies')

        var agency = []

        agenciesRef.child(payload.id).once('value', snapshot => {
          agency.push({ ...snapshot.val(), id: snapshot.key })

          if (agency[0].status != 1) {
            router.push('/agencies')
          }
        }, context.commit(GET_AGENCY_PENDING, true))
        context.commit(GET_AGENCY, agency)
      } else {
        context.commit(GET_AGENCY_STORE, payload.id)
        context.commit(GET_AGENCY_PENDING, true)
      }
    },
    updateAgencyWithImage (context, payload) {
      var user = firebase.auth().currentUser
      var agenciesRef = firebase.database().ref('agencies')
      storageRef.ref('agencies/' + user.uid + '/' + payload.imageName).put(payload.image)
        .then(() => {
          // get new photo
          var downloadImg = storageRef.ref('agencies/' + user.uid + '/' + payload.imageName)
          downloadImg.getDownloadURL().then((url) => {
            var foundedDate = payload.founded
            var foundedYear = foundedDate.getFullYear()

            payload.url = url

            agenciesRef.child(payload.agencyId).update({ name: payload.name, employees: payload.employees, email: payload.email, phone: payload.phone, website: payload.website, address: payload.address, city: payload.city, country: payload.country, project: payload.project, founded: foundedYear, desc: payload.desc, lat: payload.lat, lng: payload.lng, services: { advertising: payload.advertising, digital: payload.digital, branding: payload.branding, social: payload.social, webDev: payload.webDev, mobile: payload.mobile, commerce: payload.commerce, data: payload.data, emailMarketing: payload.emailMarketing, eventMarketing: payload.eventMarketing, cloud: payload.cloud, seo: payload.seo, graphic: payload.graphic, print: payload.print, video: payload.video }, status: 2 })
              .then(() => {
                context.commit(UPDATE_AGENCY, payload)
                router.push('/my-agencies')
                Vue.swal({
                  type: 'success',
                  title: 'Agency successfully updated',
                  text: 'Now we have to verify content you want to publish. If the verification of content succeeds your agency will appear in public directory.',
                  confirmButtonText: 'OK'
                })
              })
              .catch(error => {
                context.commit(ERROR, error.message)
                context.commit(GET_DISABLED_AGENCY, false)
              })
          })
        })
        .catch(error => {
          context.commit(ERROR, error.message)
          context.commit(GET_DISABLED_AGENCY, false)
        })
    },
    updateAgencyWithoutImage (context, payload) {
      var agenciesRef = firebase.database().ref('agencies')

      var foundedDate = payload.founded
      var foundedYear = foundedDate.getFullYear()

      agenciesRef.child(payload.agencyId).update({ name: payload.name, employees: payload.employees, email: payload.email, phone: payload.phone, website: payload.website, address: payload.address, city: payload.city, country: payload.country, project: payload.project, founded: foundedYear, desc: payload.desc, lat: payload.lat, lng: payload.lng, services: { advertising: payload.advertising, digital: payload.digital, branding: payload.branding, social: payload.social, webDev: payload.webDev, mobile: payload.mobile, commerce: payload.commerce, data: payload.data, emailMarketing: payload.emailMarketing, eventMarketing: payload.eventMarketing, cloud: payload.cloud, seo: payload.seo, graphic: payload.graphic, print: payload.print, video: payload.video }, status: 2 })
        .then(() => {
          context.commit(UPDATE_AGENCY, payload)
          router.push('/my-agencies')
          Vue.swal({
            type: 'success',
            title: 'Agency successfully updated',
            text: 'Now we have to verify content you want to publish. If the verification of content succeeds your agency will appear in public directory.',
            confirmButtonText: 'OK'
          })
        })
        .catch(error => {
          context.commit(ERROR, error.message)
          context.commit(GET_DISABLED_AGENCY, false)
        })
    },
    deleteAgency (context, payload) {
      var user = firebase.auth().currentUser
      var agenciesRef = firebase.database().ref('agencies')

      storageRef.ref('agencies/' + user.uid + '/' + payload.image).delete()
        .then(() => {
          agenciesRef.child(payload.id).remove()
          context.commit(REMOVE_AGENCY, payload.id)
          context.commit(GET_DISABLED_AGENCY, false)
          Vue.swal({
            type: 'success',
            title: 'Agency successfully deleted',
            confirmButtonText: 'OK'
          })
        }).catch(function (error) {
          context.commit(ERROR, error.message)
          context.commit(GET_DISABLED_AGENCY, false)
        })
    }
  }
}

export default agencies
