import firebase from 'firebase/app'
import Vue from 'vue'
import { GET_LOGS_PENDING, GET_LOGS, UPDATE_LOG } from '../mutation_types'

const adminlogs = {
  state: {
    logsPending: false,
    logs: null
  },
  getters: {
    getLogsPending (state) {
      return state.logsPending
    },
    getLogs (state) {
      return state.logs
    }
  },
  mutations: {
    [GET_LOGS_PENDING] (state, value) {
      state.logsPending = value
    },
    [GET_LOGS] (state, value) {
      state.logs = value
    },
    [UPDATE_LOG] (state, payload) {
      var updatedLog = state.logs.find(log => log.id === payload.id)
      updatedLog.recent = payload.recent
    }
  },
  actions: {
    getLogs (context) {
      if (!context.state.logs) {
        context.commit(GET_LOGS_PENDING, false)

        var logsRef = firebase.database().ref('logs')

        var logs = []

        logsRef.on('child_added', snapshot => {
          logs.push({ ...snapshot.val(), id: snapshot.key })
        }, context.commit(GET_LOGS_PENDING, true))

        context.commit(GET_LOGS, logs)

        setTimeout(
          function () {
            context.commit(GET_LOGS_PENDING, true)
          }, 3000
        )
      }
    },
    setAsSeen (context, payload) {
      var logsRef = firebase.database().ref('logs')

      logsRef.child(payload.id).update({ recent: false })
        .then(() => {
          context.commit(UPDATE_LOG, payload)
          Vue.swal({
            type: 'success',
            title: 'Success',
            text: 'Log has been updated',
            confirmButtonText: 'OK'
          })
        })
        .catch(error => {
          console.log(error.message)
          Vue.swal({
            type: 'error',
            title: 'Error occurred',
            text: 'Please try again later',
            confirmButtonText: 'OK'
          })
        })
    }
  }
}

export default adminlogs
