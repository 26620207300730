import firebase from 'firebase/app'
import Vue from 'vue'
import router from '../../router/router'
import { ERROR, GET_ADMIN_USER_PAYMENTS, GET_ADMIN_PAYMENTS_PENDING, GET_DISABLED_ADMIN_PAYMENT, UPDATE_ADMIN_PAYMENT, REMOVE_ADMIN_PAYMENT } from '../mutation_types'

const adminPayments = {
  state: {
    adminPayments: null,
    adminPaymentsPending: false,
    disabledAdminPayment: false,
    adminPaymentPending: false
  },
  getters: {
    getAdminPaymentsList (state) {
      return state.adminPayments
    },
    getAdminPaymentsListPending (state) {
      return state.adminPaymentsPending
    },
    getAdminPaymentsError (state) {
      return state.error
    },
    getDisabledAdminPayment (state) {
      return state.disabledAdminPayment
    },
    getAdminPaymentPending (state) {
      return state.adminPaymentPending
    }
  },
  mutations: {
    [ERROR] (state, error) {
      state.error = error
    },
    [GET_ADMIN_USER_PAYMENTS] (state, value) {
      state.adminPayments = value
    },
    [GET_ADMIN_PAYMENTS_PENDING] (state, value) {
      state.adminPaymentsPending = value
    },
    [GET_DISABLED_ADMIN_PAYMENT] (state, value) {
      state.disabledAdminPayment = value
    },
    [UPDATE_ADMIN_PAYMENT] (state, payload) {
      var updatedPayment = state.adminPayments.find(item => item.id === payload.paymentId)

      updatedPayment.number = payload.number
      updatedPayment.type = payload.type
      updatedPayment.amount = payload.amount
      updatedPayment.date = payload.date
      updatedPayment.user = payload.user
      updatedPayment.customer = payload.customer
      updatedPayment.invoice = payload.invoice
    },
    [REMOVE_ADMIN_PAYMENT] (state, value) {
      var deletedPayment = state.adminPayments.find(item => item.id == value)
      state.adminPayments.splice(state.adminPayments.indexOf(deletedPayment), 1)
    }
  },
  actions: {
    getAdminPaymentsList (context) {
      if (!context.state.adminPayments) {
        context.commit(GET_ADMIN_PAYMENTS_PENDING, false)

        var adminPaymentsRef = firebase.database().ref('payments')

        var adminPaymentsList = []

        adminPaymentsRef.on('child_added', snapshot => {
          adminPaymentsList.push({ ...snapshot.val(), id: snapshot.key })
        }, context.commit(GET_ADMIN_PAYMENTS_PENDING, true))

        context.commit(GET_ADMIN_USER_PAYMENTS, adminPaymentsList)

        setTimeout(
          function () {
            context.commit(GET_ADMIN_PAYMENTS_PENDING, true)
          }, 3000
        )
      }
    },
    clearDisabledAdminPayment (context) {
      context.commit(GET_DISABLED_ADMIN_PAYMENT, false)
    },
    setDisabledAdminPayment (context) {
      context.commit(GET_DISABLED_ADMIN_PAYMENT, true)
    },
    addAdminPayment (context, payload) {
      var paymentRef = firebase.database().ref('payments')

      paymentRef.push().set({ number: payload.number, type: Number(payload.type), amount: Number(payload.amount), date: Number(payload.date), user: payload.user, customer: payload.customer, invoice: payload.invoice })
        .then(() => {
          router.push('/admin-payments')
          Vue.swal({
            type: 'success',
            title: 'New payment added successfully',
            confirmButtonText: 'OK'
          })
        })
        .catch(error => {
          context.commit(ERROR, error.message)
          context.commit(GET_DISABLED_ADMIN_PAYMENT, false)
        })
    },
    updateAdminPayment (context, payload) {
      var paymentsRef = firebase.database().ref('payments')

      paymentsRef.child(payload.paymentId).update({ number: payload.number, type: Number(payload.type), amount: Number(payload.amount), date: Number(payload.date), user: payload.user, customer: payload.customer, invoice: payload.invoice })
        .then(() => {
          context.commit(UPDATE_ADMIN_PAYMENT, payload)
          router.push('/admin-payments')
          Vue.swal({
            type: 'success',
            title: 'Payment successfully updated',
            confirmButtonText: 'OK'
          })
        })
        .catch(function (error) {
          context.commit(ERROR, error.message)
          context.commit(GET_DISABLED_ADMIN_PAYMENT, false)
        })
    },
    deleteAdminPayment (context, payload) {
      var paymentsRef = firebase.database().ref('payments')

      paymentsRef.child(payload.id).remove()
      context.commit(REMOVE_ADMIN_PAYMENT, payload.id)
      context.commit(GET_DISABLED_ADMIN_PAYMENT, false)
      Vue.swal({
        type: 'success',
        title: 'Payment offer successfully deleted',
        confirmButtonText: 'OK'
      })
    }
  }
}

export default adminPayments
