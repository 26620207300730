<template>
    <div class="footer">
        <b-container fluid class="footer-top">
            <b-container>
                <footer class="footer-top__content">
                    <b-row>
                        <b-col cols="12" md="2">
                            <router-link to="/" exact><img class="footer-top__logo img-fluid" src="../assets/images/shared/logo.svg" alt="logo"></router-link>
                        </b-col>
                        <b-col cols="12" sm="6" md="2">
                            <h3 class="footer-top__title">About us</h3>
                            <div>
                                <router-link to="/terms" :class="this.$route.name == 'terms' ? 'footer-link' : ''" class="footer-top__link">Terms of service</router-link>
                            </div>
                            <div>
                                <router-link to="/privacy" :class="this.$route.name == 'privacy' ? 'footer-link' : ''" class="footer-top__link">Privacy policy</router-link>
                            </div>
                            <div>
                                <router-link to="/cookies" :class="this.$route.name == 'cookies' ? 'footer-link' : ''" class="footer-top__link">Cookie policy</router-link>
                            </div>
                            <div>
                                <router-link to="/faq" :class="this.$route.name == 'faq' ? 'footer-link' : ''" class="footer-top__link">FAQ</router-link>
                            </div>
                        </b-col>
                        <b-col cols="12" sm="6" md="2">
                            <h3 class="footer-top__title">Resources</h3>
                            <div>
                                <router-link to="/agencies" :class="this.$route.name == 'agencies' ? 'footer-link' : ''" class="footer-top__link">Agencies</router-link>
                            </div>
                            <div>
                                <router-link to="/events" :class="this.$route.name == 'events' ? 'footer-link' : ''" class="footer-top__link">Events</router-link>
                            </div>
                            <div>
                                <router-link to="/interviews" :class="this.$route.name == 'interviews' ? 'footer-link' : ''" class="footer-top__link">Interviews</router-link>
                            </div>
                            <div>
                                <router-link to="/map" :class="this.$route.name == 'map' ? 'footer-link' : ''" class="footer-top__link">Map</router-link>
                            </div>
                            <div>
                                <router-link to="/jobs" :class="this.$route.name == 'jobs' ? 'footer-link' : ''" class="footer-top__link">Jobs</router-link>
                            </div>
                            <div>
                                <router-link to="/statistics" :class="this.$route.name == 'statistics' ? 'footer-link' : ''" class="footer-top__link">Statistics</router-link>
                            </div>
                        </b-col>
                        <b-col cols="12" sm="6" md="2">
                            <h3 class="footer-top__title">Services</h3>
                            <div>
                                <router-link to="/sponsorship" :class="this.$route.name == 'sponsorship' ? 'footer-link' : ''" class="footer-top__link">Sponsorship</router-link>
                            </div>
                            <div>
                                <router-link to="/advertise" :class="this.$route.name == 'advertise' ? 'footer-link' : ''" class="footer-top__link">Advertise</router-link>
                            </div>
                        </b-col>
                        <b-col cols="12" sm="6" md="4">
                            <h3 class="footer-top__title mb-2">Contact us</h3>
                            <a href="mailto:contactbrandingpavilion@gmail.com" class="footer-top__link">contactbrandingpavilion@gmail.com</a>
                        </b-col>
                    </b-row>
                </footer>
            </b-container>
        </b-container>
        <b-container fluid class="footer-bottom">
            <b-container>
                <footer class="footer__content">
                    <b-row>
                        <b-col cols="12" md="8">
                            <h3 class="footer-bottom__text">Copyright © {{ getYear }} Branding Pavilion. All rights reserved. </h3>
                        </b-col>
                        <b-col cols="12" md="4">
                            <div class="footer-bottom__social">
                                <a class="footer-bottom__link" target="_blank" href="https://www.facebook.com/Branding-Pavilion-108237570712885"><v-icon name="brands/facebook" scale="1.5" /></a>
                                <a class="footer-bottom__link" target="_blank" href="https://twitter.com/BrandingPavili1"><v-icon name="brands/twitter-square" scale="1.5" /></a>
                                <!-- <a class="footer-bottom__link" target="_blank" href="#"><v-icon name="brands/linkedin" scale="1.5" /></a> -->
                            </div>
                        </b-col>
                    </b-row>
                </footer>
            </b-container>
        </b-container>
    </div>
</template>

<script>
import 'vue-awesome/icons/brands/facebook'
import 'vue-awesome/icons/brands/twitter-square'
import 'vue-awesome/icons/brands/linkedin'
import Icon from 'vue-awesome/components/Icon'
export default {
  components: {
    'v-icon': Icon,
  },
  computed: {
    getYear: function () {
      return new Date().getFullYear()
    }
  }
}
</script>
