import Vue from 'vue'
import Vuex, { Store } from 'vuex'
import user from './modules/user'
import events from './modules/events'
import jobs from './modules/jobs'
import agencies from './modules/agencies'
import adminUsers from './modules/adminUsers'
import adminJobs from './modules/adminJobs'
import adminEvents from './modules/adminEvents'
import adminAgencies from './modules/adminAgencies'
import payments from './modules/payments'
import adminPayments from './modules/adminPayments'
import adminLogs from './modules/adminLogs'
import VuexReset from '@ianwalter/vuex-reset'
import firebase from 'firebase/app'
import router from '../router/router'
import { RESET } from './mutation_types'

Vue.use(Vuex)

export const store = new Vuex.Store({
  plugins: [VuexReset()],
  mutations: {
    [RESET]: () => {}
  },
  actions: {
    signOut (context) {
      firebase.auth().signOut().then(function () {
        if (router.app._route.name == 'home') {
          router.push('/')
          context.commit(RESET)
          context.dispatch('getLatestAgencies')
          context.dispatch('getLatestEvents')
          context.dispatch('getLatestJobs')
        } else {
          router.push('/')
          context.commit(RESET)
        }
      })
    }
  },
  modules: {
    user,
    events,
    jobs,
    agencies,
    adminUsers,
    adminJobs,
    adminEvents,
    adminAgencies,
    payments,
    adminPayments,
    adminLogs
  }
})
