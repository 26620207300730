import Vue from 'vue'
import firebase from 'firebase/app'
import router from '../../router/router'
import { ERROR, GET_ADMIN_JOBS_LIST, GET_ADMIN_JOBS_PENDING, SET_JOB_STATUS, MANAGE_JOB, GET_ADMIN_JOB, GET_ADMIN_JOB_PENDING, GET_ADMIN_JOB_STORE, UPDATE_ADMIN_JOB, GET_DISABLED_ADMIN_JOB, REMOVE_ADMIN_JOB } from '../mutation_types'

const adminJobs = {
  state: {
    error: null,
    adminJobsList: null,
    adminJobsPending: false,
    adminJob: null,
    adminJobPending: false,
    disabledAdminJob: false
  },
  getters: {
    getAdminJobsError (state) {
      return state.error
    },
    getAdminJobsList (state) {
      return state.adminJobsList
    },
    getAdminJobsPending (state) {
      return state.adminJobsPending
    },
    getAdminJob (state) {
      return state.adminJob
    },
    getAdminJobPending (state) {
      return state.adminJobPending
    },
    getDisabledAdminJob (state) {
      return state.disabledAdminJob
    }
  },
  mutations: {
    [ERROR] (state, error) {
      state.error = error
    },
    [GET_ADMIN_JOBS_LIST] (state, value) {
      state.adminJobsList = value
    },
    [GET_ADMIN_JOBS_PENDING] (state, value) {
      state.adminJobsPending = value
    },
    [SET_JOB_STATUS] (state, payload) {
      var updatedJob = state.adminJobsList.find(job => job.id === payload.id)
      updatedJob.status = payload.status
      updatedJob.reason = payload.reason
    },
    [MANAGE_JOB] (state, payload) {
      var upgradedJob = state.adminJobsList.find(job => job.id === payload.id)
      upgradedJob.ribbon = payload.ribbon
    },
    [GET_ADMIN_JOB] (state, value) {
      state.adminJob = value
    },
    [GET_ADMIN_JOB_PENDING] (state, value) {
      state.adminJobPending = value
    },
    [GET_ADMIN_JOB_STORE] (state, value) {
      state.adminJob = [state.adminJobsList.find(jb => jb.id == value)]
    },
    [UPDATE_ADMIN_JOB] (state, payload) {
      var updatedJob = state.adminJobsList.find(jb => jb.id === payload.jobId)

      updatedJob.position = payload.position
      updatedJob.type = payload.type
      updatedJob.company = payload.company
      updatedJob.city = payload.city
      updatedJob.country = payload.country
      updatedJob.email = payload.email
      updatedJob.phone = payload.phone
      updatedJob.website = payload.website
      updatedJob.apply = payload.apply
      updatedJob.desc = payload.desc
      updatedJob.qual = payload.qual
      updatedJob.offer = payload.offer
      updatedJob.method = payload.method
      updatedJob.status = 2
    },
    [GET_DISABLED_ADMIN_JOB] (state, value) {
      state.disabledAdminJob = value
    },
    [REMOVE_ADMIN_JOB] (state, value) {
      var deletedJob = state.adminJobsList.find(jb => jb.id == value)
      state.adminJobsList.splice(state.adminJobsList.indexOf(deletedJob), 1)
    }
  },
  actions: {
    getAdminJobs (context) {
      if (!context.state.adminJobsList) {
        context.commit(GET_ADMIN_JOBS_PENDING, false)

        var adminJobsRef = firebase.database().ref('jobs')

        var adminJobsList = []

        adminJobsRef.on('child_added', snapshot => {
          adminJobsList.push({ ...snapshot.val(), id: snapshot.key })
        }, context.commit(GET_ADMIN_JOBS_PENDING, true))

        context.commit(GET_ADMIN_JOBS_LIST, adminJobsList)

        setTimeout(
          function () {
            context.commit(GET_ADMIN_JOBS_PENDING, true)
          }, 3000
        )
      }
    },
    verifyJobOffer (context, payload) {
      var adminJobsRef = firebase.database().ref('jobs')

      payload.status = 1
      adminJobsRef.child(payload.id).update({ status: 1 })
        .then(() => {
          context.commit(SET_JOB_STATUS, payload)
          Vue.swal({
            type: 'success',
            title: 'Success',
            text: 'Job offer verified',
            confirmButtonText: 'OK'
          })
        })
        .catch(error => {
          console.log(error.message)
          Vue.swal({
            type: 'error',
            title: 'Error occurred',
            text: 'Please try again later',
            confirmButtonText: 'OK'
          })
        })
    },
    setPendingJobOffer (context, payload) {
      var adminJobsRef = firebase.database().ref('jobs')

      payload.status = 2
      adminJobsRef.child(payload.id).update({ status: 2 })
        .then(() => {
          context.commit(SET_JOB_STATUS, payload)
          Vue.swal({
            type: 'success',
            title: 'Success',
            text: 'Job offer set as pending',
            confirmButtonText: 'OK'
          })
        })
        .catch(error => {
          console.log(error.message)
          Vue.swal({
            type: 'error',
            title: 'Error occurred',
            text: 'Please try again later',
            confirmButtonText: 'OK'
          })
        })
    },
    denyJobOffer (context, payload) {
      var adminJobsRef = firebase.database().ref('jobs')

      payload.status = 3
      adminJobsRef.child(payload.id).update({ status: 3, reason: payload.reason })
        .then(() => {
          context.commit(SET_JOB_STATUS, payload)
          Vue.swal({
            type: 'success',
            title: 'Success',
            text: 'Job offer denied',
            confirmButtonText: 'OK'
          })
        })
        .catch(error => {
          console.log(error.message)
          Vue.swal({
            type: 'error',
            title: 'Error occurred',
            text: 'Please try again later',
            confirmButtonText: 'OK'
          })
        })
    },
    upgradeJobOffer (context, payload) {
      var adminJobsRef = firebase.database().ref('jobs')

      payload.ribbon = true
      adminJobsRef.child(payload.id).update({ ribbon: true })
        .then(() => {
          context.commit(MANAGE_JOB, payload)
          Vue.swal({
            type: 'success',
            title: 'Success',
            text: 'Job offer upgraded',
            confirmButtonText: 'OK'
          })
        })
        .catch(error => {
          console.log(error.message)
          Vue.swal({
            type: 'error',
            title: 'Error occurred',
            text: 'Please try again later',
            confirmButtonText: 'OK'
          })
        })
    },
    downgradeJobOffer (context, payload) {
      var adminJobsRef = firebase.database().ref('jobs')

      payload.ribbon = false
      adminJobsRef.child(payload.id).update({ ribbon: false })
        .then(() => {
          context.commit(MANAGE_JOB, payload)
          Vue.swal({
            type: 'success',
            title: 'Success',
            text: 'Job offer downgraded',
            confirmButtonText: 'OK'
          })
        })
        .catch(error => {
          console.log(error.message)
          Vue.swal({
            type: 'error',
            title: 'Error occurred',
            text: 'Please try again later',
            confirmButtonText: 'OK'
          })
        })
    },
    getAdminJob (context, payload) {
      context.commit(GET_ADMIN_JOB_PENDING, false)
      if (!context.state.adminJob) {
        var adminJobsRef = firebase.database().ref('jobs')

        var adminJob = []

        adminJobsRef.child(payload.id).once('value', snapshot => {
          adminJob.push({ ...snapshot.val() })
        }, context.commit(GET_ADMIN_JOB_PENDING, true))
        context.commit(GET_ADMIN_JOB, adminJob)
      } else {
        context.commit(GET_ADMIN_JOB_STORE, payload.id)
        context.commit(GET_ADMIN_JOB_PENDING, true)
      }
    },
    updateAdminJob (context, payload) {
      var jobsRef = firebase.database().ref('jobs')

      jobsRef.child(payload.jobId).update({ position: payload.position, type: payload.type, company: payload.company, email: payload.email, phone: payload.phone, website: payload.website, city: payload.city, country: payload.country, apply: payload.apply, desc: payload.desc, qual: payload.qual, offer: payload.offer, method: payload.method, status: 2 })
        .then(() => {
          context.commit(UPDATE_ADMIN_JOB, payload)
          router.push('/admin-jobs')
          Vue.swal({
            type: 'success',
            title: 'Job offer successfully updated',
            confirmButtonText: 'OK'
          })
        })
        .catch(function (error) {
          context.commit(ERROR, error.message)
          context.commit(GET_DISABLED_ADMIN_JOB, false)
        })
    },
    clearDisabledAdminJob (context) {
      context.commit(GET_DISABLED_ADMIN_JOB, false)
    },
    setDisabledAdminJob (context) {
      context.commit(GET_DISABLED_ADMIN_JOB, true)
    },
    addAdminJob (context, payload) {
      var user = firebase.auth().currentUser
      var jobsRef = firebase.database().ref('jobs')

      jobsRef.push().set({ position: payload.position, type: payload.type, company: payload.company, phone: payload.phone, email: payload.email, website: payload.website, city: payload.city, country: payload.country, apply: payload.apply, desc: payload.desc, qual: payload.qual, offer: payload.offer, method: payload.method, status: 2, user: user.uid, ribbon: false })
        .then(() => {
          router.push('/admin-jobs')
          Vue.swal({
            type: 'success',
            title: 'New job offer added successfully',
            confirmButtonText: 'OK'
          })
        })
        .catch(error => {
          context.commit(ERROR, error.message)
          context.commit(GET_DISABLED_ADMIN_JOB, false)
        })
    },
    deleteAdminJob (context, payload) {
      var jobsRef = firebase.database().ref('jobs')

      jobsRef.child(payload.id).remove()
      context.commit(REMOVE_ADMIN_JOB, payload.id)
      context.commit(GET_DISABLED_ADMIN_JOB, false)
      Vue.swal({
        type: 'success',
        title: 'Job offer successfully deleted',
        confirmButtonText: 'OK'
      })
    }
  }
}

export default adminJobs
