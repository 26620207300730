import Vue from 'vue'
import firebase from 'firebase/app'
import router from '../../router/router'
import { ERROR, GET_EVENTS, GET_EVENTS_PENDING, GET_USER_EVENTS, GET_DISABLED_EVENT, GET_EVENT, GET_EVENT_PENDING, GET_USER_EVENT, GET_LATEST_EVENTS, REMOVE_EVENT, UPDATE_EVENT, GET_EVENT_STORE, GET_USER_EVENT_STORE } from '../mutation_types'

var storageRef = firebase.storage()

const events = {
  state: {
    eventsList: null,
    latestEventsList: null,
    userEventsList: null,
    error: null,
    disabled: false,
    eventsPending: false,
    disabledEvent: false,
    event: null,
    userEvent: null,
    eventPending: false
  },
  getters: {
    getEventError (state) {
      return state.error
    },
    getEvents (state) {
      return state.eventsList
    },
    getLatestEvents (state) {
      return state.latestEventsList
    },
    getUserEvents (state) {
      return state.userEventsList
    },
    getEventsPending (state) {
      return state.eventsPending
    },
    getDisabledEvent (state) {
      return state.disabledEvent
    },
    getEvent (state) {
      return state.event
    },
    getUserEvent (state) {
      return state.userEvent
    },
    getEventPending (state) {
      return state.eventPending
    }
  },
  mutations: {
    [ERROR] (state, error) {
      state.error = error
    },
    [GET_EVENTS] (state, value) {
      state.eventsList = value
    },
    [GET_LATEST_EVENTS] (state, value) {
      state.latestEventsList = value
    },
    [GET_USER_EVENTS] (state, value) {
      state.userEventsList = value
    },
    [GET_USER_EVENT_STORE] (state, value) {
      state.userEvent = [state.userEventsList.find(ev => ev.id == value)]
    },
    [GET_EVENTS_PENDING] (state, value) {
      state.eventsPending = value
    },
    [GET_DISABLED_EVENT] (state, value) {
      state.disabledEvent = value
    },
    [GET_EVENT] (state, value) {
      state.event = value
    },
    [GET_EVENT_STORE] (state, value) {
      state.event = [state.eventsList.find(ev => ev.id == value)]
    },
    [GET_USER_EVENT] (state, value) {
      state.userEvent = value
    },
    [GET_EVENT_PENDING] (state, value) {
      state.eventPending = value
    },
    [REMOVE_EVENT] (state, value) {
      var deletedEvent = state.userEventsList.find(ev => ev.id == value)
      state.userEventsList.splice(state.userEventsList.indexOf(deletedEvent), 1)
    },
    [UPDATE_EVENT] (state, payload) {
      var formatedDate = payload.date
      var dd = formatedDate.getDate()

      var mm = formatedDate.getMonth() + 1
      var yyyy = formatedDate.getFullYear()
      if (dd < 10) {
        dd = '0' + dd
      }

      if (mm < 10) {
        mm = '0' + mm
      }
      formatedDate = dd + '-' + mm + '-' + yyyy

      var updatedEvent = state.userEventsList.find(ev => ev.id === payload.eventId)
      updatedEvent.name = payload.name
      updatedEvent.type = payload.type
      updatedEvent.city = payload.city
      updatedEvent.country = payload.country
      updatedEvent.date = formatedDate
      updatedEvent.desc = payload.desc
      updatedEvent.email = payload.email
      updatedEvent.phone = payload.phone
      updatedEvent.website = payload.website
      updatedEvent.address = payload.address
      updatedEvent.lat = payload.lat
      updatedEvent.lng = payload.lng
      updatedEvent.status = 2
      if (payload.url !== undefined) {
        updatedEvent.url = payload.url
      }
    }
  },
  actions: {
    getUserEvents (context) {
      if (!context.state.userEventsList) {
        context.commit(GET_EVENTS_PENDING, false)
        var user = firebase.auth().currentUser
        var userEventsRef = firebase.database().ref('events').orderByChild('user').equalTo(user.uid)
        var eventsList = []

        userEventsRef.on('child_added', snapshot => {
          if (context.state.userEventsList !== null) {
            var stateEvent = context.state.userEventsList.find(ev => ev.id == snapshot.key)
            if (stateEvent !== undefined) {
              return false
            } else {
              eventsList.push({ ...snapshot.val(), id: snapshot.key })
            }
          } else {
            eventsList.push({ ...snapshot.val(), id: snapshot.key })
          }
        }, context.commit(GET_EVENTS_PENDING, true))

        context.commit(GET_USER_EVENTS, eventsList)

        setTimeout(
          function () {
            context.commit(GET_EVENTS_PENDING, true)
          }, 3000
        )
      }
    },
    getEvents (context) {
      if (!context.state.eventsList) {
        context.commit(GET_EVENTS_PENDING, false)

        var eventsRef = firebase.database().ref('events').orderByChild('status').equalTo(1)

        var eventsList = []

        eventsRef.on('child_added', snapshot => {
          if (context.state.eventsList !== null) {
            var stateEvent = context.state.eventsList.find(ev => ev.id == snapshot.key)
            if (stateEvent !== undefined) {
              return false
            } else {
              eventsList.push({ ...snapshot.val(), id: snapshot.key })
            }
          } else {
            eventsList.push({ ...snapshot.val(), id: snapshot.key })
          }
        }, context.commit(GET_EVENTS_PENDING, true))

        context.commit(GET_EVENTS, eventsList)

        setTimeout(
          function () {
            context.commit(GET_EVENTS_PENDING, true)
          }, 3000
        )
      }
    },
    getLatestEvents (context) {
      if (!context.state.latestEventsList) {
        context.commit(GET_EVENTS_PENDING, false)

        var eventsRef = firebase.database().ref('events').orderByChild('status').equalTo(1).limitToLast(10)

        var eventsList = []

        eventsRef.on('child_added', snapshot => {
          if (context.state.latestEventsList !== null) {
            var stateEvent = context.state.latestEventsList.find(ev => ev.id == snapshot.key)
            if (stateEvent !== undefined) {
              return false
            } else {
              eventsList.push({ ...snapshot.val(), id: snapshot.key })
            }
          } else {
            eventsList.push({ ...snapshot.val(), id: snapshot.key })
          }
        }, context.commit(GET_EVENTS_PENDING, true))

        context.commit(GET_LATEST_EVENTS, eventsList)

        setTimeout(
          function () {
            context.commit(GET_EVENTS_PENDING, true)
          }, 3000
        )
      }
    },
    addEvent (context, payload) {
      var user = firebase.auth().currentUser
      var eventsRef = firebase.database().ref('events')
      storageRef.ref('events/' + user.uid + '/' + payload.imageName).put(payload.image)
        .then(() => {
          // get new photo
          var downloadImg = storageRef.ref('events/' + user.uid + '/' + payload.imageName)
          downloadImg.getDownloadURL().then((url) => {
            var formatedDate = payload.date
            var dd = formatedDate.getDate()

            var mm = formatedDate.getMonth() + 1
            var yyyy = formatedDate.getFullYear()
            if (dd < 10) {
              dd = '0' + dd
            }

            if (mm < 10) {
              mm = '0' + mm
            }
            formatedDate = dd + '-' + mm + '-' + yyyy

            eventsRef.push().set({ name: payload.name, type: payload.type, email: payload.email, phone: payload.phone, website: payload.website, address: payload.address, city: payload.city, country: payload.country, date: formatedDate, desc: payload.desc, lat: payload.lat, lng: payload.lng, url: url, status: 2, user: user.uid, ribbon: false, image: payload.imageName })
              .then(() => {
                router.push('/my-events')
                Vue.swal({
                  type: 'success',
                  title: 'New event added successfully',
                  text: 'Now we have to verify content you want to publish. If the verification of content succeeds your event will appear in public directory.',
                  confirmButtonText: 'OK'
                })
              })
              .catch(error => {
                context.commit(ERROR, error.message)
                context.commit(GET_DISABLED_EVENT, false)
              })
          })
        })
        .catch(error => {
          context.commit(ERROR, error.message)
          context.commit(GET_DISABLED_EVENT, false)
        })
    },
    setDisabledEvent (context) {
      context.commit(GET_DISABLED_EVENT, true)
    },
    clearDisabledEvent (context) {
      context.commit(GET_DISABLED_EVENT, false)
    },
    getEvent (context, payload) {
      context.commit(GET_EVENT_PENDING, false)
      if (!context.state.eventsList) {
        var eventsRef = firebase.database().ref('events')

        var event = []

        eventsRef.child(payload.id).once('value', snapshot => {
          event.push({ ...snapshot.val() })

          if (event[0].status != 1) {
            router.push('/events')
          }
        }, context.commit(GET_EVENT_PENDING, true))
        context.commit(GET_EVENT, event)
      } else {
        context.commit(GET_EVENT_STORE, payload.id)
        context.commit(GET_EVENT_PENDING, true)
      }
    },
    getUserEvent (context, payload) {
      context.commit(GET_EVENT_PENDING, false)
      if (!context.state.userEventsList) {
        var user = firebase.auth().currentUser

        var eventsRef = firebase.database().ref('events')

        var event = []

        eventsRef.child(payload.id).once('value', snapshot => {
          event.push({ ...snapshot.val() })

          if (event[0].user != user.uid) {
            router.push('/401')
          }
        }, context.commit(GET_EVENT_PENDING, true))
        context.commit(GET_USER_EVENT, event)
      } else {
        context.commit(GET_USER_EVENT_STORE, payload.id)
        context.commit(GET_EVENT_PENDING, true)
      }
    },
    updateEventWithImage (context, payload) {
      var user = firebase.auth().currentUser
      var eventsRef = firebase.database().ref('events')
      storageRef.ref('events/' + user.uid + '/' + payload.imageName).put(payload.image)
        .then(() => {
          // get new photo
          var downloadImg = storageRef.ref('events/' + user.uid + '/' + payload.imageName)
          downloadImg.getDownloadURL().then((url) => {
            var formatedDate = payload.date
            var dd = formatedDate.getDate()

            var mm = formatedDate.getMonth() + 1
            var yyyy = formatedDate.getFullYear()
            if (dd < 10) {
              dd = '0' + dd
            }

            if (mm < 10) {
              mm = '0' + mm
            }
            formatedDate = dd + '-' + mm + '-' + yyyy

            payload.url = url

            eventsRef.child(payload.eventId).update({ name: payload.name, type: payload.type, email: payload.email, phone: payload.phone, website: payload.website, address: payload.address, city: payload.city, country: payload.country, date: formatedDate, desc: payload.desc, lat: payload.lat, lng: payload.lng, status: 2 })
              .then(() => {
                context.commit(UPDATE_EVENT, payload)
                router.push('/my-events')
                Vue.swal({
                  type: 'success',
                  title: 'Event successfully updated',
                  text: 'Now we have to verify content you want to publish. If the verification of content succeeds your event will appear in public directory.',
                  confirmButtonText: 'OK'
                })
              })
              .catch(function (error) {
                context.commit(ERROR, error.message)
                context.commit(GET_DISABLED_EVENT, false)
              })
          })
        })
        .catch(function (error) {
          context.commit(ERROR, error.message)
          context.commit(GET_DISABLED_EVENT, false)
        })
    },
    updateEventWithoutImage (context, payload) {
      var eventsRef = firebase.database().ref('events')
      var formatedDate = payload.date
      var dd = formatedDate.getDate()

      var mm = formatedDate.getMonth() + 1
      var yyyy = formatedDate.getFullYear()
      if (dd < 10) {
        dd = '0' + dd
      }

      if (mm < 10) {
        mm = '0' + mm
      }
      formatedDate = dd + '-' + mm + '-' + yyyy

      eventsRef.child(payload.eventId).update({ name: payload.name, type: payload.type, email: payload.email, phone: payload.phone, website: payload.website, address: payload.address, city: payload.city, country: payload.country, date: formatedDate, desc: payload.desc, lat: payload.lat, lng: payload.lng, status: 2 })
        .then(() => {
          context.commit(UPDATE_EVENT, payload)
          router.push('/my-events')
          Vue.swal({
            type: 'success',
            title: 'Event successfully updated',
            text: 'Now we have to verify content you want to publish. If the verification of content succeeds your event will appear in public directory.',
            confirmButtonText: 'OK'
          })
        })
        .catch(function (error) {
          context.commit(ERROR, error.message)
          context.commit(GET_DISABLED_EVENT, false)
        })
    },
    deleteEvent (context, payload) {
      var user = firebase.auth().currentUser
      var eventsRef = firebase.database().ref('events')

      storageRef.ref('events/' + user.uid + '/' + payload.image).delete()
        .then(() => {
          eventsRef.child(payload.id).remove()
          context.commit(REMOVE_EVENT, payload.id)
          context.commit(GET_DISABLED_EVENT, false)
          Vue.swal({
            type: 'success',
            title: 'Event successfully deleted',
            confirmButtonText: 'OK'
          })
        }).catch(function (error) {
          context.commit(ERROR, error.message)
          context.commit(GET_DISABLED_EVENT, false)
        })
    }
  }
}

export default events
