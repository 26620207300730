import firebase from 'firebase/app'
import { GET_USER_PAYMENTS, GET_PAYMENTS_PENDING } from '../mutation_types'

const payments = {
  state: {
    userPayments: null,
    paymentsPending: false
  },
  getters: {
    getUserPayments (state) {
      return state.userPayments
    },
    getPaymentsPending (state) {
      return state.paymentsPending
    }
  },
  mutations: {
    [GET_USER_PAYMENTS] (state, value) {
      state.userPayments = value
    },
    [GET_PAYMENTS_PENDING] (state, value) {
      state.paymentsPending = value
    }
  },
  actions: {
    getUserPayments (context) {
      if (!context.state.userPayments) {
        context.commit(GET_PAYMENTS_PENDING, false)
        var user = firebase.auth().currentUser

        var paymentsRef = firebase.database().ref('payments').orderByChild('user').equalTo(user.uid)

        var payments = []

        paymentsRef.on('child_added', snapshot => {
          payments.push({ ...snapshot.val(), id: snapshot.key })
        }, context.commit(GET_PAYMENTS_PENDING, true))

        context.commit(GET_USER_PAYMENTS, payments)

        setTimeout(
          function () {
            context.commit(GET_PAYMENTS_PENDING, true)
          }, 3000
        )
      }
    }
  }
}

export default payments
